import { lineItemsColumnDefsGrid } from './lineItemsColumnDefs';
import { defaultGridOptions } from '../../constant';
import lineItemsRowTemplate from './html/rowTemplate.html';
import lineItemsFooterTemplate from './html/lineItemsFooterTemplate.html';
import { reductionColumnDefs } from "./reductionColumnDefs";

angular
    .module('CareGuard')
    .controller('lineItemsController', lineItemsController);

lineItemsController.$inject = [
    'uiGridConstants',
    '$toastr',
    'claimService',
    'billTypeId',
    'accountService',
    'gridUtils',
    'claimDetailStatusId',
    'codeService',
    'LxDialogService',
    '$scope',
    'documentService',
    'billReviewService',
    '$q',
    'lineItemsService',
    'utilService',
    'lookupService'
];

function lineItemsController(
    uiGridConstants,
    $toastr,
    claimService,
    billTypeId,
    accountService,
    gridUtils,
    claimDetailStatusId,
    codeService,
    LxDialogService,
    $scope,
    documentService,
    billReviewService,
    $q,
    lineItemsService,
    utilService,
    lookupService
) {

    let vm = this;

    const isClaimDetailPage = !!vm.isClaimDetail;

    vm.hasLineItemsGridChanged = hasLineItemsGridChanged;
    vm.isClaimInPaidStatus = isClaimInPaidStatus;
    vm.updateLineItem = updateLineItem;
    vm.bulkUpdateClaimDetailStatuses = bulkUpdateClaimDetailStatuses;
    vm.claimDetailStatusIdForBulkUpdate = 0;
    vm.deleteLineItemRow = deleteLineItemRow;
    vm.addLineItemRow = addLineItemRow;
    vm.cancelLineItemsGridChanges = cancelLineItemsGridChanges;
    vm.getTooltipForSaveCancelButtons = getTooltipForSaveCancelButtons;
    vm.getRevenueCodes = getRevenueCodes;
    vm.getProcedureCodes = getProcedureCodes;
    vm.getNDCCodes = getNDCCodes;
    vm.getModifierCodes = getModifierCodes;
    vm.selectRevenueCode = selectRevenueCode;
    vm.selectProcedureCode = selectProcedureCode;
    vm.selectNDCCode = selectNDCCode;
    vm.selectPlaceOfService = selectPlaceOfService;
    vm.selectM1 = selectM1;
    vm.selectM2 = selectM2;
    vm.selectM3 = selectM3;
    vm.selectM4 = selectM4;
    vm.onBlurM1 = onBlurM1;
    vm.onBlurM2 = onBlurM2;
    vm.onBlurM3 = onBlurM3;
    vm.onBlurM4 = onBlurM4;
    vm.onBlurRevenue = onBlurRevenue;
    vm.onBlurProcedure = onBlurProcedure;
    vm.onBlurNdc = onBlurNdc;
    vm.onBlurPlaceOfService = onBlurPlaceOfService;
    vm.isClaimPaid = isClaimPaid;

    vm.currentDate = (new Date()).toISOString();
    vm.defaultClaimStatus = {};
    vm.isClaimStatusInternalReductionAllowed = isClaimStatusInternalReductionAllowed;
    vm.isRxClaimByBillTypeId = isRxClaimByBillTypeId;

    vm.validateDiagnosisCodePointers = validateDiagnosisCodePointers;
    vm.normalizeDiagnosisCodePointers = normalizeDiagnosisCodePointers;

    let dataReferences = {
        gridOptionsLineItems: []
    };

    vm.gridOptionsLineItems = defaultGridOptions({
        columnDefs: lineItemsColumnDefsGrid,
        rowHeight: 48,
        headerHeight: 48,
        minRowsToShow: 2,
        virtualizationThreshold: 150,
        excessRows: 150,
        flatEntityAccess: true,
        rowTemplate: lineItemsRowTemplate,
        enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
        enablePaginationControls: false,
        enableSorting: false,
        enableCellEditOnFocus: true,
        useExternalPagination: false,
        useExternalSorting: false,
        totalItems: 0,
        enableExpandableRowHeader: false,
        gridFooterTemplate: lineItemsFooterTemplate,
        showGridFooter: true
    });

    vm.gridOptionsLineItems.invalidFields = {};
    vm.gridOptionsLineItems.customValidationErrors = [];

    vm.placeOfServiceLookup = [];

    vm.gridOptionsLineItemsArr = [vm.gridOptionsLineItems, vm.gridOptionsLineItems];

    vm.hasLineReductions = hasLineReductions;

    const reductionDialogId = 'reductionDialog';
    vm.openReductionPopup = openReductionPopup;
    vm.closeReductionPopup = closeReductionPopup;
    vm.hasRole = hasRole;
    vm.setOpenDetailFeeRecalculationPopupFunction = setOpenDetailFeeRecalculationPopupFunction;
    vm.openDetailFeeRecalculationPopup = null;

    vm.gridReductionOptionList = [];

    $scope.$watchCollection("vm.reductions", function (newValues, oldValues) {
        if (newValues?.length > 0) {
            initReductionGrids();
        }
    });

    $scope.$watchCollection("vm.claim.lineItems", function (newValues, oldValues) {
        if (newValues?.length > 0) {
            fillLineItemsGrid();
        }
    });

    var maxReductionLinesToShow = 5;

    (() => {
        fillLineItemsGrid();

        (!vm.claimDetailStatuses?.length ? getClaimDetailStatuses() : $q.when(true))
            .then(() => {
                setDefaultClaimDetailStatus();
                vm.nonZeroPayClaimDetailStatuses = vm.claimDetailStatuses.filter(status => !status.category.isZeroPay);
            });

        if (!vm.lookupdata?.quantityMeasurements?.length) {
            getMeasurementData();
        }

        if (!vm.placeOfServiceLookup.length) {
            getPlaceOfServiceLookup();
        }
    })();

    function getPlaceOfServiceLookup() {
        return lookupService.getLookUp('placeofservice').then(({ Data: lookup }) => vm.placeOfServiceLookup = lookup);
    }

    function setOpenDetailFeeRecalculationPopupFunction(openFunction) {
        vm.openDetailFeeRecalculationPopup = openFunction;
    }

    function getClaimDetailStatuses() {
        return claimService.getClaimDetailStatuses().then(({ data: statuses }) => {
            vm.claimDetailStatuses = statuses;
            return $q.when(true);
        });
    }

    function setDefaultClaimDetailStatus() {
        vm.defaultClaimStatus = vm.claimDetailStatuses?.find(status => status.id === claimDetailStatusId.PricedAccordingToReview);
    }

    function getMeasurementData() {
        codeService.getQuantityMeasurements().then(({ data: measurements }) => {
            vm.lookupdata.quantityMeasurements = measurements;
        });
    }

    function hasLineItemsGridChanged() {
        return gridUtils.hasGridChanged(vm.gridOptionsLineItems.data, dataReferences.gridOptionsLineItems);
    }

    function isClaimInPaidStatus() {
        if (!vm.claim || !vm.claim.claimStatusID) return;

        return claimService.isStatusAPaidStatus(vm.claim.claimStatusID);
    }

    function hasLineReductions() {
        return vm.reductions && vm.reductions.length > 0;
    }

    function hasRole(role) {
        return accountService.isInRole(role);
    }

    function deleteLineItemRow(lineItem) {
        if (isClaimInPaidStatus()) return;

        vm.gridOptionsLineItems.data = vm.gridOptionsLineItems?.data?.filter(item => item.rowId !== lineItem.rowId);
        vm.gridOptionsLineItems.invalidFields = {};
        vm.gridOptionsLineItems.data = gridUtils.populateRowId(vm.gridOptionsLineItems?.data);
        vm.claim.lineItems = vm.gridOptionsLineItems?.data;
    }

    function addLineItemRow() {
        if (isClaimInPaidStatus()) return;

        const emptyRow = gridUtils.getEmptyRowEntityFromColumnDefs(lineItemsColumnDefsGrid);
        emptyRow.rowId = gridUtils.generateRowId(vm.gridOptionsLineItems?.data);
        emptyRow.lineNumber = gridUtils.generateLineItemNumber(vm.gridOptionsLineItems?.data);
        emptyRow.statusID = vm.defaultClaimStatus.id;
        emptyRow.serviceDate = vm.currentDate;
        emptyRow.serviceDateEnd = vm.currentDate;
        emptyRow.units = 1;
        emptyRow.billedAmount = 0;
        emptyRow.billReviewAmount = 0;
        emptyRow.networkAmount = 0;
        emptyRow.outOfNetworkAmount = 0;
        emptyRow.payableAmount = 0;
        emptyRow.feeAmount = 0;
        emptyRow.internalReductionAmount = 0;

        vm.gridOptionsLineItems.data.push(emptyRow);
        vm.claim.lineItems = vm.gridOptionsLineItems?.data;
    }

    function reprocessCellDeletes() {
        var results = vm.gridOptionsLineItems;
        var lineItemRows = results.data["length"];

        for (let i = 0; i < lineItemRows; i++) {
            var modifier1 = results.data[i].modifier1;
            var modifier2 = results.data[i].modifier2;
            var modifier3 = results.data[i].modifier3;
            var modifier4 = results.data[i].modifier4;
            var revenue = results.data[i].revenueServiceCode;
            var procedure = results.data[i].procedureServiceCode;
            var ndc = results.data[i].ndcServiceCode;

            if (modifier1 === '')
                results.data[i].modifier1ID = null;
            if (modifier2 === '')
                results.data[i].modifier2ID = null;
            if (modifier3 === '')
                results.data[i].modifier3ID = null;
            if (modifier4 === '')
                results.data[i].modifier4ID = null;
            if (revenue === '')
                results.data[i].revenueServiceCodeID = null;
            if (procedure === '')
                results.data[i].procedureServiceCodeID = null;
            if (ndc === '')
                results.data[i].ndcServiceCodeID = null;
        }
    }

    function updateLineItem({ skipRecalculateFeesPopup = false, recalculateFeesAction = false, bulkUpdateDetailStatus = false } = {}) {
        var errorResponse = $q.reject(null);

        reprocessCellDeletes(); // line items ui-grid and angucomplete act erratic when there is only one line item.

        if (!vm.gridOptionsLineItems?.data?.length) {
            $toastr.show('Please enter at least one Line Item.', 'warning');
            return errorResponse;
        }

        let validationResults = gridUtils.validateLineItemsGrid(
            vm.gridOptionsLineItems,
            vm.claim,
            getDiagnosisSequenceSelector());
        vm.gridOptionsLineItems.invalidFields = validationResults.invalidFields;
        vm.gridOptionsLineItems.customValidationErrors = validationResults.customValidationErrors;

        if (!angular.equals({}, vm.gridOptionsLineItems.invalidFields)) {
            $toastr.show('Please fill the required fields before saving!', 'warning');
            return errorResponse;
        }

        if (validationResults.customValidationErrors?.length) {
            $toastr.show('Please correct invalid data before saving!', 'warning');
            return errorResponse;
        }

        if (!skipRecalculateFeesPopup &&
            lineItemsService.haveClaimReductionsChanged(vm.gridOptionsLineItems.data, dataReferences.gridOptionsLineItems)) {
            vm.openDetailFeeRecalculationPopup();
            return errorResponse;
        }

        if (recalculateFeesAction && !accountService.isInRole('ClaimActions')) {
            $toastr.show('Error - access is required to recalculate fees. Cannot save claim details.', 'warning');
            return errorResponse;
        }

        const lineItemsHaveNonZeroReductions = vm.claim.lineItems.some(lineItem =>
            lineItem.billReviewAmount > 0 ||
            lineItem.networkAmount > 0 ||
            lineItem.internalReductionAmount > 0 ||
            lineItem.outOfNetworkAmount > 0
        );

        if (!vm.claim.billReview.hasLineItemReductions && vm.claim.billReview.hasLineItemReductions !== undefined && lineItemsHaveNonZeroReductions) {
            let msg = `For ${vm.claim.billReview.billReviewVendorName} claims, only enter savings amount for the claim. Do not enter savings for each line.`;
            $toastr.show(msg, 'error');
            return errorResponse;
        }

        //Required to get modifier codes prior to saving line items
        var lineItemsPayload = vm.gridOptionsLineItems.data = lineItemsService.mapToCareHubModelAndFormatDates(vm.gridOptionsLineItems?.data, vm.claim.id);

        if (bulkUpdateDetailStatus) {
            if (!vm.claimDetailStatusIdForBulkUpdate) {
                $toastr.show('Please select a claim detail status.', 'warning');
                return errorResponse;
            } else {
                lineItemsPayload = lineItemsPayload.map(item => {
                    return { ...item, statusID: vm.claimDetailStatusIdForBulkUpdate };
                });
            }
        }

        return claimService.saveClaimDetail(lineItemsPayload, vm.claim.id).then(() => {
            return $q.when(recalculateFeesAction ? claimService.recalculateFees(vm.claim.id) : null);
        }).then(() => {
            return (bulkUpdateDetailStatus ? markEorDeleted() : $q.when(true))
                .then(() => documentService.fillClaimDocumentData(vm.claim));
        }).then(() => {
            let lineItemsPromise = getNewLineItems(lineItemsPayload).length > 0
                ? populateLineItemIds(vm.claim.id, lineItemsPayload)
                : $q.when(lineItemsPayload);

            return lineItemsPromise.then(lineItems => {
                const promises = [];

                promises.push(lineItemsService.saveClaimDetailDiagnosisPointers(
                    vm.claim.id,
                    lineItems,
                    vm.claim.diagnosisList,
                    getDiagnosisSequenceSelector()));

                const placesOfService = lineItemsService.mapClaimDetailPlacesOfService(lineItems);
                promises.push(claimService.saveClaimDetailPlacesOfService(vm.claim.id, placesOfService));

                return $q.all(promises);
            });
        })
        .then(vm.reloadClaim)
        .then(() => {
            fillLineItemsGrid();
        })
        .catch(err => {
            $toastr.show(err.data?.title || utilService.parseErrorMessage(err), `error`);
        });
    }

    function markEorDeleted() {
        if (vm.claim.files.currentEor) {
            return billReviewService.setCurrentEor(vm.claim.id, vm.claim.files.currentEor.documentId, false);
        }
        return $q.when(true);
    }

    function bulkUpdateClaimDetailStatuses() {
        return updateLineItem({ bulkUpdateDetailStatus: true }).then(() => {
            vm.claimDetailStatusIdForBulkUpdate = 0;
        });
    }

    function fillLineItemsGrid() {
        if (angular.equals({}, vm.claim) || !vm.claim.lineItems) return;

        vm.gridOptionsLineItems.data = gridUtils.populateRowId(vm.claim.lineItems);
        angular.copy(vm.gridOptionsLineItems.data, dataReferences.gridOptionsLineItems);
        vm.claim.gridOptionsLineItems = vm.gridOptionsLineItems;
    }

    function cancelLineItemsGridChanges() {
        if (!dataReferences.gridOptionsLineItems || !dataReferences.gridOptionsLineItems.length) return;

        angular.copy(dataReferences.gridOptionsLineItems, vm.gridOptionsLineItems.data);
        vm.claim.lineItems = vm.gridOptionsLineItems?.data;
        vm.gridOptionsLineItems.invalidFields = {};
    }

    function getTooltipForSaveCancelButtons() {
        if (isClaimInPaidStatus() || !hasLineItemsGridChanged()) {
            return `Claim has already been paid and cannot be edited or the line items data hasn't been changed!`;
        }
    }

    function getRevenueCodes(query) {
        return codeService.searchRevenueCodes({ code: query }).then(({ data }) => data);
    }

    function getProcedureCodes(query) {
        return codeService.searchProcedureCodes({ code: query }).then(({ data }) => data);
    }

    function getNDCCodes(query) {
        return codeService.searchNDCCodes({ code: query }).then(({ data }) => data);
    }

    function getModifierCodes(query) {
        if (!query) return;

        return codeService.searchModifiers({ code: query }).then(({ data }) => data);
    }

    function selectServiceCode({ selected, entity, propertyName, propertyIdName, propertyDescription }) {
        if (!entity[propertyName]) {
            entity[propertyName] = null;
            entity[propertyIdName] = null;
            return;
        }

        if (!selected || angular.equals({}, selected)) return;

        entity[propertyName] = selected.originalObject.code;
        entity[propertyIdName] = selected.originalObject.id;
        entity[propertyDescription] = selected.originalObject.description;
    }

    function selectRevenueCode(selected, entity) {
        selectServiceCode({ selected, entity, propertyName: 'revenueServiceCode', propertyIdName: 'revenueServiceCodeID', propertyDescription: 'revenueDescription' });
    }

    function selectProcedureCode(selected, entity) {
        selectServiceCode({ selected, entity, propertyName: 'procedureServiceCode', propertyIdName: 'procedureServiceCodeID', propertyDescription: 'procedureDescription' });
    }

    function selectNDCCode(selected, entity) {
        selectServiceCode({ selected, entity, propertyName: 'ndcServiceCode', propertyIdName: 'ndcServiceCodeID', propertyDescription: 'ndcDescription' });
    }

    function selectM1(selected, entity) {
        selectServiceCode({ selected, entity, propertyName: 'modifier1', propertyIdName: 'modifier1ID' });
    }

    function selectM2(selected, entity) {
        selectServiceCode({ selected, entity, propertyName: 'modifier2', propertyIdName: 'modifier2ID' });
    }

    function selectM3(selected, entity) {
        selectServiceCode({ selected, entity, propertyName: 'modifier3', propertyIdName: 'modifier3ID' });
    }

    function selectM4(selected, entity) {
        selectServiceCode({ selected, entity, propertyName: 'modifier4', propertyIdName: 'modifier4ID' });
    }

    function onBlurM1(entity) {
        onBlurModifier({ entity, propertyName: 'modifier1', propertyIdName: 'modifier1ID' });
    }

    function onBlurM2(entity) {
        onBlurModifier({ entity, propertyName: 'modifier2', propertyIdName: 'modifier2ID' });
    }

    function onBlurM3(entity) {
        onBlurModifier({ entity, propertyName: 'modifier3', propertyIdName: 'modifier3ID' });
    }

    function onBlurM4(entity) {
        onBlurModifier({ entity, propertyName: 'modifier4', propertyIdName: 'modifier4ID' });
    }

    function onBlurRevenue(entity) {
        onBlurModifier({ entity, propertyName: 'revenueServiceCode', propertyIdName: 'revenueServiceCodeID' });
    }

    function onBlurProcedure(entity) {
        onBlurModifier({ entity, propertyName: 'procedureServiceCode', propertyIdName: 'procedureServiceCodeID' });
    }

    function onBlurNdc(entity) {
        onBlurModifier({ entity, propertyName: 'ndcServiceCode', propertyIdName: 'ndcServiceCodeID' });
    }

    function onBlurPlaceOfService(entity) {
        if (!entity.placeOfServiceCodeRaw) {
            entity.placeOfServiceCode = null;
            entity.placeOfServiceCodeRaw = null;
            return;
        }

        const existingEntity = dataReferences.gridOptionsLineItems[entity.rowId - 1];
        if (entity.placeOfServiceCode != existingEntity?.placeOfServiceCode) {
            entity.placeOfServiceCode = existingEntity?.placeOfServiceCode;
            entity.placeOfServiceCodeRaw = existingEntity?.placeOfServiceCodeRaw;
        }
    }

    function onBlurModifier({ entity, propertyName, propertyIdName }) {
        if (!entity[propertyIdName] || !entity[propertyName]) {
            entity[propertyIdName] = null;
            return;
        }

        if (entity[propertyIdName] && dataReferences.gridOptionsLineItems[entity.rowId - 1] && entity[propertyIdName] === dataReferences.gridOptionsLineItems[entity.rowId - 1][propertyName]) return;

        entity[propertyName] = dataReferences.gridOptionsLineItems[entity.rowId - 1][propertyName];
        entity[propertyIdName] = dataReferences.gridOptionsLineItems[entity.rowId - 1][propertyIdName];
    }

    function selectPlaceOfService(selected, entity) {
        if (!entity.placeOfServiceCodeRaw) {
            entity.placeOfServiceCode = null;
            return;
        }

        if (!selected || angular.equals({}, selected)) return;

        entity.placeOfServiceCode = selected.originalObject.DataSetValue;
        entity.placeOfServiceCodeRaw = selected.originalObject.DataSetValue;
    }

    function openReductionPopup() {
        vm.selectedBillReviewResultId = vm.claim.billReview.billReviewResultId;
        LxDialogService.open(reductionDialogId);
    }

    function closeReductionPopup(save) {
        if (!save) {
            vm.selectedBillReviewResultId = undefined;
            return;
        }

        vm.selectedBillReviewResultId = +vm.selectedBillReviewResultId;

        // locate reductions
        var reductionLines = null;
        for (var i = 0; i < vm.reductions.length; ++i) {
            var vendorReductions = vm.reductions[i];

            if (vendorReductions.BillReviewResultId == vm.selectedBillReviewResultId) {
                reductionLines = vendorReductions.Lines;
                break;
            }
        }

        if (!reductionLines) {
            throw new Error('Unable to locate reductions for selected vendor');
        }

        for (var i = 0; i < reductionLines.length; ++i) {
            var reduction = reductionLines[i];
            var sourceLine = vm.claim.lineItems.find(li => li.lineNumber == reduction.LineNumber);
            updateLineItemReductions(sourceLine, reduction);

            var sourceUiLine = vm.gridOptionsLineItems.data.find(li => li.lineNumber == reduction.LineNumber);
            updateLineItemReductions(sourceUiLine, reduction);
        }

        billReviewService.setCurrentBillReviewResult(vm.claim.billReview.workflowInstanceId, vm.selectedBillReviewResultId)
            .then(() => {
                var brVendorEor = vm.claim.files.allEors.find(e => e.billReviewResultId == vm.selectedBillReviewResultId);
                return billReviewService.setCurrentEor(vm.claim.id, brVendorEor.documentId, true);
            })
            .catch(() => { return true; }) // since BR has changed, copying EOR failure is less critical then applying claim line reductions
            .then(() => {
                return updateLineItem({
                    skipRecalculateFeesPopup: true,
                    recalculateFeesAction: false
                });
            })
            .then(() => {
                LxDialogService.close(reductionDialogId);
            });
    }

    function updateLineItemReductions(sourceLine, reduction) {
        if (!sourceLine) {
            var error = new Error(`Unable to locate claim line ${reduction.LineNumber} in order to apply reduction`);
            alert(error.message);
            throw error;
        }
        sourceLine.billedAmount = reduction.BilledAmount;
        sourceLine.billReviewAmount = reduction.BillReviewAmount;
        sourceLine.networkAmount = reduction.NetworkAmount;
        sourceLine.outOfNetworkAmount = reduction.OutOfNetworkAmount;
        sourceLine.payableAmount = reduction.PayableAmount;
        sourceLine.feeAmount = reduction.FeeAmount;
        sourceLine.statusID = claimDetailStatusId.PricedAccordingToReview;
        sourceLine.internalReductionAmount = 0;
    }

    function initReductionGrids() {
        for (var i = 0; i < vm.reductions.length; ++i) {
            var billReviewVendorOptions = defaultGridOptions({
                columnDefs: reductionColumnDefs,
                rowHeight: 48,
                headerHeight: 48,
                virtualizationThreshold: 150,
                excessRows: 150,
                flatEntityAccess: true,
                rowTemplate: lineItemsRowTemplate,
                enablePaginationControls: false,
                enableSorting: false,
                enableCellEditOnFocus: true,
                useExternalPagination: false,
                useExternalSorting: false,
                totalItems: 0,
                enableExpandableRowHeader: false,
                showGridFooter: false
            });
            billReviewVendorOptions.data = getReductionDisplayLines(vm.reductions[i].Lines, vm.claim.lineItems);
            var dataLength = billReviewVendorOptions.data.length;
            if (dataLength > maxReductionLinesToShow) {
                billReviewVendorOptions.enableVerticalScrollbar = uiGridConstants.scrollbars.ALWAYS
                billReviewVendorOptions.minRowsToShow = maxReductionLinesToShow;
            }
            else {
                billReviewVendorOptions.enableVerticalScrollbar = uiGridConstants.scrollbars.NEVER
                billReviewVendorOptions.minRowsToShow = dataLength;
            }
            vm.gridReductionOptionList.push(billReviewVendorOptions);
        }
    }

    function getReductionDisplayLines(sourceLines, claimLineItems) {
        if (sourceLines.length == 0) {
            return [];
        }

        var lines = angular.copy(sourceLines);

        var totalNetworkAmount = 0;
        var totalOutOfNetworkAmount = 0;
        var totalPayableAmount = 0;
        var totalFeeAmount = 0;
        var totalBilledAmount = 0;
        var totalBillReviewAmount = 0;

        for (var i = 0; i < lines.length; ++i) {
            var line = lines[i]
            totalNetworkAmount += line.NetworkAmount;
            totalOutOfNetworkAmount += line.OutOfNetworkAmount;
            totalPayableAmount += line.PayableAmount;
            totalFeeAmount += line.FeeAmount;
            totalBillReviewAmount += line.BillReviewAmount;
            totalBilledAmount += line.BilledAmount;
            lines[i].ProcedureServiceCode = claimLineItems[i].procedureServiceCode;
        }

        var newLine = {};
        newLine.LineNumber = 'Total';
        newLine.NetworkAmount = totalNetworkAmount;
        newLine.OutOfNetworkAmount = totalOutOfNetworkAmount;
        newLine.PayableAmount = totalPayableAmount;
        newLine.FeeAmount = totalFeeAmount;
        newLine.BilledAmount = totalBilledAmount;
        newLine.BillReviewAmount = totalBillReviewAmount

        lines.push(newLine);
        return lines;
    }

    vm.billedAmountChanged = function (detail) {
        if (!detail?.billedAmount)
            detail.billedAmount = 0;

        if (!validateAmountChange(detail)) return;
        updatePayable(detail);
    }

    vm.billReviewAmountChanged = function (detail) {
        if (!detail?.billReviewAmount)
            detail.billReviewAmount = 0;

        if (!validateAmountChange(detail)) return;
        updatePayable(detail);
    }

    vm.networkAmountChanged = function (detail) {
        if (!detail?.networkAmount)
            detail.networkAmount = 0;

        if (!validateAmountChange(detail)) return;
        updatePayable(detail);
    }

    vm.outOfNetworkAmountChanged = function (detail) {
        if (!detail?.outOfNetworkAmount)
            detail.outOfNetworkAmount = 0;

        if (!validateAmountChange(detail)) return;
        updatePayable(detail);
    }

    vm.feeAmountChanged = function (detail) {
        if (!detail?.feeAmount)
            detail.feeAmount = 0;

        if (!validateAmountChange(detail)) return;
    }

    vm.internalReductionAmountChanged = function (detail) {
        if (!detail?.internalReductionAmount)
            detail.internalReductionAmount = 0;

        if (!validateAmountChange(detail)) return;
        updatePayable(detail);
    }

    function validateAmountChange(detail) {
        if (!detail || !detail.statusID || isZeroPay(detail.statusID)) {
            $toastr.show(`Savings and fee total cannot be added to line items that are zero pay type.`, `warning`);
            return false;
        }

        const savingsAndFeeTotals = detail.billReviewAmount + detail.networkAmount + detail.outOfNetworkAmount + detail.feeAmount;
        if (savingsAndFeeTotals > detail.billedAmount) {
            $toastr.show(`Savings and fee total cannot exceed billed amount.`, `warning`);
            return false;
        }

        return true;
    }

    function updatePayable(detail) {
        if (!detail || !detail.statusID || isZeroPay(detail.statusID)) return;

        const payable = detail.billedAmount - detail.billReviewAmount - detail.networkAmount - detail.outOfNetworkAmount - (detail.internalReductionAmount ?? 0);
        detail.payableAmount = Number(payable.toFixed(2));
    }

    vm.claimDetailStatusChanged = function (detail) {
        if (!detail || !detail.statusID || !isZeroPay(detail.statusID)) return;

        resetNonBilledDetailAmounts(detail);
    }

    function resetNonBilledDetailAmounts(detail) {
        detail.billReviewAmount = 0;
        detail.networkAmount = 0;
        detail.outOfNetworkAmount = 0;
        detail.payableAmount = 0;
        detail.feeAmount = 0;
        detail.internalReductionAmount = 0;
    }

    function isRxClaimByBillTypeId() {
        return vm.claim.billTypeID === billTypeId.Rx;
    }

    function isClaimStatusInternalReductionAllowed() {
        if (!vm.claim || !vm.claim.claimStatusID) return;
        return claimService.doesStatusAllowInternalReduction(vm.claim.claimStatusID);
    }

    function isZeroPay(paramClaimDetailStatusId) {
        return vm.claimDetailStatuses.find(x => x.id == parseInt(paramClaimDetailStatusId)).category.isZeroPay;
    }

    function isClaimPaid() {
        return vm.claim.paidDate;
    }

    function getDiagnosisSequenceSelector() {
        if (isClaimDetailPage) {
            return diagnosis => diagnosis.sequence;
        }

        return diagnosis => diagnosis.rowId;
    }

    function validateDiagnosisCodePointers(lineItem) {
        return gridUtils.validateDiagnosisCodePointersForLineItem(lineItem, vm.claim, getDiagnosisSequenceSelector());
    }

    function normalizeDiagnosisCodePointers(lineItem){
        if (!lineItem.diagnosisCodePointersPlain) return;

        let normalized = lineItem.diagnosisCodePointersPlain
            .replace(/[^a-zA-Z]/g, '')
            .toUpperCase()
            .slice(0, 4);

        lineItem.diagnosisCodePointersPlain = removeLetterDuplicates(normalized);
    }

    function removeLetterDuplicates(str) {
        let result = '';
        for (let i = 0; i < str.length; i++) {
            if (result.indexOf(str[i]) === -1) {
                result += str[i];
            }
        }

        return result;
    }

    function populateLineItemIds(claimId, existingLineItems) {
        return claimService.getClaimDetailsByClaimId(claimId)
            .then(({ data: updatedLineItems }) => assignCreatedLineItemIds(existingLineItems, updatedLineItems));
    }

    function assignCreatedLineItemIds(existingLineItems, updatedLineItems) {
        for (let existing of getNewLineItems(existingLineItems)) {
            const updated = updatedLineItems.find(x => x.lineNumber === existing.lineNumber);

            if (!existing) throw new Error(`Line item with line number = ${existing.lineNumber} is not found`);

            existing.id = updated.id;
        }

        return existingLineItems;
    }

    function getNewLineItems(lineItems) {
        return lineItems.filter(lineItem => !lineItem.id);
    }
}
