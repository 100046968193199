import { providersDefsGrid } from './providersColumnDefs';
import { notesDefsGrid } from './notesColumnDefs';
import { docsDefsGrid } from './docsColumnDefs';
import { benefitsDefsGrid } from './benefitsColumnDefs';
import { holdAmountsDefsGrid } from './holdAmountsColumnDefs';
import { claimHistoryDefsGrid } from './claimHistoryColumnDefs';
import { billReviewReprocessColumnDefs } from './billReviewReprocessColumnDefs';
import { defaultGridOptions } from '../../constant';
import { ruleResultsDefsGrid } from './ruleResultsColumnDefs';
import { billReviewRuleResultsColumnDefs } from './billReviewRuleResultsColumnDefs';
import { sharepointDocsDefsGrid } from './sharepointDocsColumnDefs';
import rowTemplate from './html/rowTemplate.html';
import providersFooterTemplate from './html/providers/providersFooterTemplate.html';
import notesFooterTemplate from './html/notes/notesFooterTemplate.html';
import ruleResultsFooterTemplate from './html/rules/ruleResultFooterTemplate.html';
import billReviewRuleResultsFooterTemplate from './html/rules/billReviewRuleResultFooterTemplate.html';

angular
	.module('CareGuard')
	.controller('claimDetailController', claimDetailController);

claimDetailController.$inject = [
	'claimService',
	'notesService',
	'documentService',
	'sharepointDocumentService',
	'benefitsService',
	'bankingService',
	'LxDialogService',
	'$timeout',
	'$scope',
	'$q',
	'webStorageService',
	'lookupService',
	'memberService',
	'$state',
	'accountService',
	'utilService',
	'payeeService',
	'codeService',
	'providerService',
	'addressService',
	'claimsOtherActions',
	'$toastr',
	'uiGridConstants',
	'claimStatusId',
	'claimDetailStatusId',
	'documentTypeId',
	'billEntryTypeId',
	'billTypeId',
	'billReviewService',
	'addressType',
	'transactionType',
	'cancelReasonId',
	'dispositionService',
	'dispositionStatusId',
	'memberFlagsService',
	'ruleResultsEnum',
	'getUrlService',
	'gridUtils',
	'billReviewVendors',
	'lineItemsService',
	'billReviewSagaService',
	'billReviewReprocessStatus',
	'claimCloneTypeEnum',
	'billReviewReductionTypes',
	'billReviewWorkflows',
	'billReviewWorkflowService'
];

function claimDetailController(
	claimService,
	notesService,
	documentService,
	sharepointDocumentService,
	benefitsService,
	bankingService,
	LxDialogService,
	$timeout,
	$scope,
	$q,
	webStorage,
	lookupService,
	memberService,
	$state,
	accountService,
	utilService,
	payeeService,
	codeService,
	providerService,
	addressService,
	claimsOtherActions,
	$toastr,
	uiGridConstants,
	claimStatusId,
	claimDetailStatusId,
	documentTypeId,
	billEntryTypeId,
	billTypeId,
	billReviewService,
	addressType,
	transactionType,
	cancelReasonId,
	dispositionService,
	dispositionStatusId,
	memberFlagsService,
	ruleResultsEnum,
	getUrlService,
	gridUtils,
	billReviewVendors,
	lineItemsService,
	billReviewSagaService,
	billReviewReprocessStatus,
	claimCloneTypeEnum,
	billReviewReductionTypes,
	billReviewWorkflows,
	billReviewWorkflowService
) {

	let vm = this;


	const previousStateWebStorageKey = 'previousStateForClaimDetails';
	const webStorageType = 'localStorage';
	const storedPreviousState = webStorage.get(previousStateWebStorageKey, webStorageType) || {};
	const claimValue = $state.params.claimValue;
	const zeroPayDialogId = 'zeroPayDialogId';
	const openCloneDialogId = 'cloneDialogId';
	const payeeAddressSelectionPopupId = 'payeeAddressSelection';
	const billReviewReprocessPopupId = 'billReviewReprocessPopup';
	const changeBillReviewVendorPopupId = 'changeBillReviewVendorPopup'

	vm.memberData = $state.params.memberID || $state.params.previousMemberID || storedPreviousState.memberID || storedPreviousState.previousMemberID;

	const dispositionStatusesRequiredParentClaimId = [
		dispositionStatusId.Duplicate,
		dispositionStatusId.Resubmission,
		dispositionStatusId.Reconsideration,
		dispositionStatusId.Clone
	];

	vm.documentFilterTypes = {
		data: [
			{ name: "Show All Documents", filterByClaimId: 0 },
			{ name: "Show Related Documents", filterByClaimId: 1 }
		]
	};
	vm.filterDocumentsByClaimId = 0;

	vm.filterData = {};

	vm.getMemberDocumentsData = getMemberDocumentsData;
	vm.isSharepointDocumentsLoaded = false;
	vm.member = {};
	vm.claims = { id: parseInt(claimValue) };
	vm.lookupdata = {};
	vm.original = {};
	vm.isNew = false;
	vm.isEditing = false;
	vm.paidDateUpdatedByStatusChange = false;
	vm.otherReasonOption = {};
	vm.StopPaymentRequest = {};
	vm.changeStatus = {};
	vm.hasServiceCodesSearchResult = false;
	vm.serviceCodesSearchResult = [];
	vm.isStopPayment = false;
	vm.stopPaymentButtonDisabled = false;
	vm.stopPaymentData = {};
	vm.OtherReason = {};
	vm.isNewCheckNeeded = false;
	vm.isRushDeliveryNeeded = false;
	vm.taxid = undefined;
	vm.followup = {
		data: [
			{ ID: 1, DataSetValue: 'Yes' },
			{ ID: 2, DataSetValue: 'No' }
		]
	};
	vm.payMember = false;
	vm.claimHierarchy = {};
	vm.NewMemberNumber = null;
	vm.isDataLoading = false;
	vm.saveClaimOptions = { skipTrustAccountConfirmation: false, skipRecalculateFeesPopup: false, recalculateFeesAction: false };

	vm.getUrlFromState = getUrlFromState;
	vm.validateParentClaimAsChild = validateParentClaimAsChild;
	vm.changeDispositionDisabled = changeDispositionDisabled;
	vm.saveClaims = saveClaims;
	vm.clone = clone;
	vm.setNewPayee = setNewPayee;
	vm.navigate = navigate;
	vm.getImageFile = getImageFile;
	vm.changeAccount = changeAccount;
	vm.hasRole = hasRole;
	vm.stopPayment = stopPayment;
	vm.sendSPREmail = sendSPREmail;
	vm.generateCustomEOR = generateCustomEOR;
	vm.removeFees = removeFees;
	vm.removeSavings = removeSavings;
	vm.isClaimInPaidStatus = isClaimInPaidStatus;
	vm.isClaimPaidByACH = isClaimPaidByACH;
	vm.openZeroPayDialog = openZeroPayDialog;
	vm.openCloneDialog = openCloneDialog;
	vm.closeCloneDialog = closeCloneDialog;
	vm.completeZeroPay = completeZeroPay;
	vm.selectOwner = selectOwner;
	vm.addNoteRow = addNoteRow;
	vm.saveProviders = saveProviders;
	vm.addProviderRow = addProviderRow;
	vm.deleteProviderRow = deleteProviderRow;
	vm.cancelProvidersGridChanges = cancelProvidersGridChanges;
	vm.isShowNotesIndicator = isShowNotesIndicator;
	vm.addNotesRow = addNotesRow;
	vm.deleteNotesRow = deleteNotesRow;
	vm.cancelNotesGridChanges = cancelNotesGridChanges;
	vm.saveNoteRow = saveNoteRow;
	vm.canNoteBeEdited = canNoteBeEdited;
	vm.editNote = editNote;
	vm.cancelEditNote = cancelEditNote;
	vm.canNoteBeDeleted = canNoteBeDeleted;
	vm.canNoteBeAdded = canNoteBeAdded;
	vm.getDocumentFile = getDocumentFile;
	vm.openPopupToChangeAccount = openPopupToChangeAccount;
	vm.closeChangeAccountPopup = closeChangeAccountPopup;
	vm.openStopPaymentPopup = openStopPaymentPopup;
	vm.closeStopPaymentPopup = closeStopPaymentPopup;
	vm.searchParentClaimQuery = claimService.getSearchClaims();
	vm.searchTaxId = `${payeeService.searchPayeesRoute()}?searchBy=`;
	vm.selectParentClaim = selectParentClaim;
	vm.switchEditParentClaim = switchEditParentClaim;
	vm.saveNewParentClaim = saveNewParentClaim;
	vm.isEditParentClaimAvailable = isEditParentClaimAvailable;
	vm.clearParentClaim = clearParentClaim;
	vm.validateParentClaim = validateParentClaim;
	vm.validateParentClaimShouldNotExists = validateParentClaimShouldNotExists;
	vm.isEditClaimNumberAvailable = isEditClaimNumberAvailable;
	vm.getClaimHistoryGridHeight = getClaimHistoryGridHeight;
	vm.getBillReviewReprocessGridHeight = getBillReviewReprocessGridHeight;
	vm.getClaimHierarchyMargin = getClaimHierarchyMargin;
	vm.isCurrentHierarchyClaim = isCurrentHierarchyClaim;
	vm.getRuleResultsGridHeight = getRuleResultsGridHeight;
	vm.isShowRulesIndicator = isShowRulesIndicator;
	vm.switchEditTaxId = switchEditTaxId;
	vm.isEditTaxIdAvailable = isEditTaxIdAvailable;
	vm.isPayeeChangeAllowed = isPayeeChangeAllowed;
	vm.getUsersRequestFn = memberService.getUsersParameterFormat;
	vm.setOpenBillReviewAssignmentPopupFunction = setOpenBillReviewAssignmentPopupFunction;
	vm.openBillReviewAssignmentPopup = null;
	vm.openBillReviewAssignmentPopupPromise = null;
	vm.setOpenFeeRecalculationPopupFunction = setOpenFeeRecalculationPopupFunction;
	vm.openFeeRecalculationPopup = null;
	vm.openFeeRecalculationPopupPromise = null;
	vm.setOpenTrustAccountReimbursementConfirmationPopupFunction = setOpenTrustAccountReimbursementConfirmationPopupFunction;
	vm.openTrustAccountReimbursementConfirmationPopupFunction = null;
	vm.openTrustAccountReimbursementConfirmationPopupFunctionPromise = null;
	vm.assignBillReviewVendor = assignBillReviewVendor;
	vm.isShowBillReviewRulesIndicator = isShowBillReviewRulesIndicator;
	vm.getBillReviewRuleResultsGridHeight = getBillReviewRuleResultsGridHeight;
	vm.deleteHoldAmountRow = deleteHoldAmountRow;
	vm.canHoldAmountBeDeleted = canHoldAmountBeDeleted;
	vm.isShowHoldAmountsIndicator = isShowHoldAmountsIndicator;
	vm.calculateFees = calculateFees;
	vm.refreshDiagnosis = refreshDiagnosis;
	vm.loadClaim = loadClaim;
	vm.confirmTrustAccount = confirmTrustAccount;
	vm.completeFeeRecalculationPopup = completeFeeRecalculationPopup;

	vm.openCreateBillReviewReprocessPopup = openCreateBillReviewReprocessPopup;
	vm.closeBillReviewReprocessPopup = closeBillReviewReprocessPopup;
	vm.createBillReviewReprocess = createBillReviewReprocess;
	vm.isBillReviewReprocessButtonDisabled = isBillReviewReprocessButtonDisabled;
	vm.getBillReviewReprocessButtonTooltip = getBillReviewReprocessButtonTooltip;
	vm.hasCurrentBillReviewReprocessChanged = hasCurrentBillReviewReprocessChanged;
	vm.updateCurrentBillReviewReprocess = updateCurrentBillReviewReprocess;
	vm.resetCurrentBillReviewReprocess = resetCurrentBillReviewReprocess;
	vm.canCurrentBillReviewReprocessBeEdited = canCurrentBillReviewReprocessBeEdited;
	vm.isShowBillReviewReprocessIndicator = isShowBillReviewReprocessIndicator;
	vm.isBillTypeCodeRequired = isBillTypeCodeRequired;
	vm.isDRGRequired = isDRGRequired;

	vm.toggleShowAllRuleResults = toggleShowAllRuleResults;
	vm.getRuleResultCheckboxLabel = getRuleResultCheckboxLabel;
	vm.toggleShowAllBillReviewRuleResults = toggleShowAllBillReviewRuleResults;
	vm.getBillReviewRuleResultCheckboxLabel = getBillReviewRuleResultCheckboxLabel;

	vm.openChangeBillReviewVendorPopup = openChangeBillReviewVendorPopup;
	vm.closeChangeBillReviewVendorPopup = closeChangeBillReviewVendorPopup;
	vm.changeBillReviewWorkflow = changeBillReviewWorkflow;
	vm.isChangeBrVendorDisabled = isChangeBrVendorDisabled;
	vm.filterOutCurrentBrVendor = function (workflow) {
		return workflow.id != vm.claims.billReview.billReviewWorkflowId;
	}

	vm.updateSavings = updateSavings;
	vm.canBeZeroPaid = canBeZeroPaid;
	vm.billReviewIsMedicare = billReviewIsMedicare;
	vm.billReviewIsLiability = billReviewIsLiability;

	let dataReferences = {
		gridOptionsProviders: [],
		gridOptionsNotes: [],
		gridOptionsLineItems: [],
		currentBillReviewReprocess: {},
		gridOptionsDiagnosis: [],
		gridOptionsHeaderData: {},
		gridOptionsPayeeData: {},
		parentClaimId: null
	};

	let allowedBillTypeIds = [];
	let trustAllocationTypeIds = [];
	let receiptBillTypeIds = [];
	let temp = {};

	let originalRuleResults = [];
	let originalBillReviewRuleResults = [];

	const defaultParentState = 'layoutNoNav.claims';
	const notesState = 'layout.notes';
	const claimsHistoryState = 'layout.claims';
	const amethystClaimsParentState = 'layoutAmethyst.amethystclaims';

	vm.parentState = $state.params.parentState || storedPreviousState.parentState || defaultParentState;
	vm.isNotesParentState = vm.parentState === notesState;
	vm.isClaimHistoryParentState = vm.parentState === claimsHistoryState;
	vm.isClaimsParentState = vm.parentState === defaultParentState;
	vm.isAmethystClaimsParentState = vm.parentState === amethystClaimsParentState;
	vm.newClaimDetailStatusID = null;
	vm.claimCloneTypeId = null;

	vm.currentBillReviewReprocess = null;
	vm.newBillReviewReprocess = null;
	vm.billReviewReprocessReasons = [
		{ id: 1, value: 'VOID ORIGINAL - Keying Error' },
		{ id: 2, value: 'VOID ORIGINAL - Duplicate Error' },
		{ id: 3, value: 'VOID ORIGINAL - [Custom Message]' }
	];
	vm.customBillReviewReprocessReasonId = 3;

	vm.billReviewReprocessStatuses = billReviewReprocessStatus;

	const customBillReviewReprocessReasonPrefix = 'VOID ORIGINAL - ';

	const billReviewReprocessDisableConditions = [
		{
			condition: () => !!vm.currentBillReviewReprocess,
			tooltip: 'There\'s a pending bill review reprocess'
		},
		{
			condition: () => vm.claims.billReview && !vm.claims.billReview.billReviewReceivedDate,
			tooltip: 'Claim can\'t be re-sent to Bill Review before the previous Bill Review results were received'
		},
		{
			condition: () => vm.claims.billReview && (vm.claims.billReview.billReviewWorkflowId == billReviewWorkflows.FirstHealth),
			tooltip: 'Reprocess is unavailable for claims assigned to First Health bill review provider'
		},
		{
			condition: () => {
				if (billReviewIsMedicare()) {
					return true;
				}

				return false;

			},
			tooltip: 'Reprocess is unavailable for Medicare claims'
		}
	];

	const removeFeeActionName = 'Remove the Fee Amount';
	const removeFeeAction = claimsOtherActions.data.find(item => item.Name === removeFeeActionName);
	const defaultNotesType = 'Claims';
	const defaultFollowupStatus = 'No';
	const changeMemberDialogID = 'changeAccount';
	const stopPaymentDialogID = 'stopPayment';
	const receiptBillTypeID = billEntryTypeId.Receipt;
	const editablePayeeFields = ['npiNumber', 'name', 'taxId', 'providerNeedsReview', 'achEligible', 'exBillReview'];
	const editableHeaderFields = ['stopWhenReceived', 'reconsiderationNote', 'isReconsideration', 'isExpediteRemittance', 'claimNumber', 'billTypeCode', 'billEntryTypeID', 'dispositionTypeId', 'claimStatusID', 'billTypeID', 'savingsAmount', 'payableAmount'];

	const searchParamsForBenefits = {
		PageSize: 10,
		PageIndex: 1,
		SortOrder: 'ASC',
		SortColumn: 'Code'
	};

	const docsGridSearchParams = {
		PageSize: 10,
		PageIndex: 1,
		SortOrder: 'ASC',
		SortColumn: 'CreatedDate'
	};

	const notesGridSearchParams = {
		PageSize: 10,
		PageIndex: 1,
		SortOrder: 'DESC',
		SortColumn: 'CreatedDate'
	};

	const sharepointDocsGridSearchParams = {
		PageSize: 10,
		PageIndex: 1,
		SortOrder: 'ASC',
		SortColumn: 'CreatedOn'
	};

	vm.canBeCloned = () => vm.claims?.claimStatusID !== claimStatusId.Paid;
	vm.claimLineReductions = [];
	vm.billReviewVendors = [];
	for (var p in billReviewVendors) {
		if (billReviewVendors.hasOwnProperty(p)) {
			vm.billReviewVendors.push({
				id: billReviewVendors[p],
				vendor: p
			});
		}
	}

	vm.redirectToNonRedeemedClaimsPage = redirectToNonRedeemedClaimsPage;
	vm.isClaimNonRedeemed = isClaimNonRedeemed;

	vm.assignClaimToPreBillReviewFlowDisabled = assignClaimToPreBillReviewFlowDisabled;
	vm.assignClaimToPreBillReviewFlow = assignClaimToPreBillReviewFlow;

	// all available statuses
	vm.allClaimStatuses = [];
	// currently filtered statuses
	vm.claimStatuses = [];

	init();

	function init() {
		vm.isDataLoading = true;

		$q.all([
			getLookupSetData(),
			getDispositionData(),
			getClaimStatusData(),
			getClaimDetailStatusData()
		]).then(() => {
			return $q.all([
				getClaimData(claimValue),
				getDiagnosisCodeData(claimValue),
				getProviderData(claimValue),
				getTransactionData(claimValue),
				getNonRedeemedClaimData(claimValue),
				getDocumentData(vm.claims),
				getFileData(claimValue),
				getStopPaymentData(claimValue),
				getNoteData(claimValue),
				getHistoryData(claimValue),
				getBillReviewReprocessData(claimValue),
				getRuleResultsData(claimValue),
				getHierarchyData(claimValue),
				getBillReviewRuleResultsData(claimValue),
				getBillReviewInstance(claimValue)
			]);
		}).then(() => {
			const { memberID, payeeID, payeeAddressID, billReviewVendorID, ppoNetworkID, claimStatusID, diagnosisList } = vm.claims;

			return $q.all([
				getClaimDetailData(claimValue, diagnosisList),
				getPayeeData({ payeeID, payeeAddressID }),
				getPPONetworkData(ppoNetworkID),
				getMemberData(memberID),
				getMemberPayeeData(memberID),
				getMemberDocumentsData(memberID),
				getMemberBenefitsData(memberID),
				getMemberHoldData(memberID),
				getMemberFlagData(memberID),
				getDataForClaimReductions(claimValue),
				setStopPaymentBasedOnStatus(claimStatusID),
			])
		}).then(() => getWorkCompFeeScheduleAmount())
			.finally(_ => {
				vm.isDataLoading = false;
			});
		getDocumentTypes();
	};

	function loadClaim() {
		return $q.all([
			getClaimData(claimValue),
			getClaimDetailData(claimValue, vm.claims.diagnosisList),
			getHierarchyData(claimValue),
			getBillReviewReprocessData(claimValue)
		]).then(() => {
			const { memberID, payeeID, payeeAddressID, billReviewVendorID, ppoNetworkID } = vm.claims;

			return $q.all([
				getPayeeData({ payeeID, payeeAddressID }),
				getPPONetworkData(ppoNetworkID),
				getMemberData(memberID).then(() => getWorkCompFeeScheduleAmount()),
				getMemberPayeeData(memberID),
				getBillReviewInstance(claimValue),
				getDataForClaimReductions(claimValue),
				resetSaveClaimOptions()
			])
		});
	}

	function resetSaveClaimOptions() {
		vm.saveClaimOptions.skipTrustAccountConfirmation = false;
		vm.saveClaimOptions.skipRecalculateFeesPopup = false;
		vm.saveClaimOptions.recalculateFeesAction = false;
	}

	function setStopPaymentBasedOnStatus(claimStatusID) {
		if ([claimStatusId.NonRedeemedPayment,
		claimStatusId.ReadyForReissue
		].includes(claimStatusID))
			vm.stopPaymentButtonDisabled = true;
	}

	function getLookupSetData() {
		const categories = [
			'accountstatus',
			'billentrytype',
			'cancelReason',
			'claimtype',
			'documenttype',
			'followupcontacttype',
			'notetype',
			'providertype',
			'statecode',
			'fundtype'
		];

		return lookupService.getLookUpsBatch({ LookupCategories: categories }).then(lookups => assignLookupValues(lookups));
	}

	function assignLookupValues(lookups) {
		for (const key in lookups) {
			vm.lookupdata[key.toLowerCase()] = lookups[key];
		}

		vm.otherReasonOptionID = getOtherCancelReasonId();
		allowedBillTypeIds = getAllowedBillTypeIds();
		receiptBillTypeIds = getReceiptBillTypeIds();
		trustAllocationTypeIds = getTrustAllocationTypeIds();
	}

	function getOtherCancelReasonId() {
		const { DataSetID: otherReasonOptionID } = vm.lookupdata.cancelreason.find(reason => reason.DataSetID === cancelReasonId.Other);
		return otherReasonOptionID;
	}

	function getAllowedBillTypeIds() {
		const allowedBillTypeValues = [
			'Receipt-RX',
			'Receipt-DME',
			'Receipt-HHC',
			'Receipt-Provider',
			'Receipt-Other'
		];

		return vm.lookupdata.claimtype.filter(type => allowedBillTypeValues.includes(type.DataSetValue)).map(type => type.DataSetID);
	}

	function getReceiptBillTypeIds() {
		const receiptBillTypes = [
			'Unknown',
			'HHC',
			'Receipt-Other',
			'Receipt-RX',
			'Receipt-DME',
			'Receipt-HHC',
			'Receipt-Provider',
			'Member Withdrawal'
		];

		return vm.lookupdata.claimtype.filter(type => receiptBillTypes.includes(type.DataSetValue)).map(type => type.DataSetID);
	}

	function getTrustAllocationTypeIds() {
		const trustAllocationTypes = [
			'Trust - MSA',
			'Trust - Other'
		];

		return vm.lookupdata.fundtype.filter(type => trustAllocationTypes.includes(type.DataSetValue)).map(type => type.DataSetID);
	}

	function getDispositionData() {
		dispositionService.getAll().then(({ Data: dispositions }) => {
			vm.dispositions = dispositions.sort(function (a, b) {
				return ('' + a.TypeName).localeCompare(b.TypeName);
			});
		});
	}

	function getClaimStatusData() {
		return claimService.getClaimStatuses().then(({ data: statuses }) => vm.claimStatuses = vm.allClaimStatuses = statuses);
	}

	function getClaimDetailStatusData() {
		return claimService.getClaimDetailStatuses().then(({ data: statuses }) => {
			vm.claimDetailStatuses = statuses;

			vm.zeroPayClaimDetailStatuses = statuses.filter(status => status.category.isZeroPay);
		});
	}

	function getClaimData(id) {
		return claimService.searchClaims({ claimIDs: [id] }).then(({ data: { items: claims } }) => {
			if (!claims || !claims?.length) {
				$toastr.show(`Claim ${id} was not found.`, `error`);
				return;
			}

			return assignClaim(claims[0]);
		});
	}

	function assignClaim(claim) {
		vm.claims = Object.assign(vm.claims, claim);
		vm.claimStatuses = getAvailableClaimStatuses();
		vm.claims.billTypeCode = vm.claims.billTypeCode?.trim();

		//Update data references
		dataReferences.gridOptionsHeaderData = getEditableClaimHeaderFields(vm.claims);

		return vm.claims;
	}

	function getAvailableClaimStatuses() {
		let newStatusOptions = vm.allClaimStatuses;

		newStatusOptions = newStatusOptions?.filter(status => !claimService.isOneTimeSystemStatus(status.id)
			|| status.id == vm.claims.claimStatusID);

		vm.changeStatus.allowed = true;
		if (!accountService.isInRole('CareGuardFinance')) {
			newStatusOptions = newStatusOptions.filter(status => claimService.isBasicEditableStatus(status.id));
			const currentStatus = vm.allClaimStatuses.find(status => status.id === vm.claims.claimStatusID);
			if (!claimService.isBasicEditableStatus(currentStatus?.id)) {
				vm.changeStatus.statusDisplayName = currentStatus?.status;
				vm.changeStatus.allowed = false;
			}
		}

		return newStatusOptions;
	}

	function refreshDiagnosis() {
		return getDiagnosisCodeData(claimValue)
			.then(diagnoses => getClaimDetailDiagnosisPointers(vm.claims.id, vm.claims.lineItems, diagnoses));
	}

	function getClaimDetailData(id, diagnoses) {
		return claimService.getClaimDetailsByClaimId(id)
			.then(({ data: details }) => {
				const promises = [];
				promises.push(fillDetailCodes(details));
				promises.push(getClaimDetailDiagnosisPointers(id, details, diagnoses));
				promises.push(getClaimDetailPlacesOfService(id, details));

				return $q.all(promises);
			})
			.then(([details]) => assignDetails(details));
	}


	function getWorkCompFeeScheduleAmount() {
		vm.wcfsAmount = (vm.member.InsuranceType === 'Liability')
			? null
			: vm.claims.billedAmount - vm.claims.lineItems.reduce((accumulator, currentLine) => accumulator + currentLine.billReviewAmount, 0);

		return vm.wcfsAmount;
	}

	function fillDetailCodes(details) {
		let promises = [];

		for (let detail of details) {
			if (detail.procedureServiceCodeID)
				promises.push(codeService.getServiceCodeById(detail.procedureServiceCodeID));
			if (detail.revenueServiceCodeID)
				promises.push(codeService.getServiceCodeById(detail.revenueServiceCodeID));
			if (detail.ndcServiceCodeID)
				promises.push(codeService.getServiceCodeById(detail.ndcServiceCodeID));
			if (detail.modifier1ID)
				promises.push(codeService.getServiceCodeById(detail.modifier1ID));
			if (detail.modifier2ID)
				promises.push(codeService.getServiceCodeById(detail.modifier2ID));
			if (detail.modifier3ID)
				promises.push(codeService.getServiceCodeById(detail.modifier3ID));
			if (detail.modifier4ID)
				promises.push(codeService.getServiceCodeById(detail.modifier4ID));
		}

		return $q.all(promises).then(results => {
			const codes = results.map(result => result.data);
			const codeMap = codes.reduce((map, serviceCode) => map.set(serviceCode.id, { code: serviceCode.code, description: serviceCode.description }), new Map());

			for (let detail of details) {
				if (detail.procedureServiceCodeID) {
					detail.procedureServiceCode = codeMap.get(detail.procedureServiceCodeID)?.code;
					detail.procedureDescription = codeMap.get(detail.procedureServiceCodeID)?.description;
				}
				if (detail.revenueServiceCodeID) {
					detail.revenueServiceCode = codeMap.get(detail.revenueServiceCodeID)?.code;
					detail.revenueDescription = codeMap.get(detail.revenueServiceCodeID)?.description;
				}
				if (detail.ndcServiceCodeID) {
					detail.ndcServiceCode = codeMap.get(detail.ndcServiceCodeID)?.code;
					detail.ndcDescription = codeMap.get(detail.ndcServiceCodeID)?.description;
				}
				if (detail.modifier1ID)
					detail.modifier1 = codeMap.get(detail.modifier1ID)?.code;
				if (detail.modifier2ID)
					detail.modifier2 = codeMap.get(detail.modifier2ID)?.code;
				if (detail.modifier3ID)
					detail.modifier3 = codeMap.get(detail.modifier3ID)?.code;
				if (detail.modifier4ID)
					detail.modifier4 = codeMap.get(detail.modifier4ID)?.code;
			}

			return details;
		});
	}

	function assignDetails(details) {
		vm.claims.lineItems = details;
		//Assign row Id prior to copying so that changes can be accurately depicted from gridOptionsLineItems
		angular.copy(gridUtils.populateRowId(vm.claims.lineItems), dataReferences.gridOptionsLineItems);

		return vm.claims.lineItems;
	}

	function getClaimDetailDiagnosisPointers(claimId, lineItems, diagnoses) {
		return claimService.getClaimDetailDiagnosisPointers(claimId).then(({ data: pointers }) => {
			const pointersPlain = lineItemsService.mapDiagnosisCodePointersToPlain(
				pointers,
				lineItems,
				diagnoses,
				getDiagnosisSequenceSelector()
			);

			lineItems.forEach(lineItem => {
				const pointer = pointersPlain.find(pointer => pointer.lineItemId === lineItem.id);
				lineItem.diagnosisCodePointersPlain = pointer.diagnosisCodePointersPlain;
			});

			return lineItems;
		});
	}

	function getClaimDetailPlacesOfService(claimId, lineItems) {
		return claimService.getClaimDetailPlacesOfService(claimId).then(({ data: placesOfService }) => {
			if (!placesOfService?.length) return;

			if (placesOfService?.length !== lineItems.length) throw new Error("Places of service don't match line items");

			lineItems.forEach(lineItem => {
				const placeOfService = placesOfService.find(place => place.claimDetailsId === lineItem.id);
				if (!placeOfService) throw new Error(`Place of service is not found for line number = ${lineItem.lineNumber}`);

				lineItem.placeOfServiceCode = placeOfService.placeOfServiceCode;
				lineItem.placeOfServiceCodeRaw = placeOfService.placeOfServiceCode;
			});

			return lineItems;
		});
	}

	function getDiagnosisCodeData(id) {
		return codeService.getDiagnosisCodesByClaimId(id).then(({ data: diagnosisCodes }) => {
			vm.claims.diagnosisList = diagnosisCodes;
			angular.copy(gridUtils.populateRowId(vm.claims.diagnosisList), dataReferences.gridOptionsDiagnosis);
			return vm.claims.diagnosisList;
		});
	}

	function getProviderData(id) {
		return providerService.getProvidersByClaimIds({ ids: [id] }).then(({ data: providers }) => {
			return fillProviderAddresses(providers);
		});
	}

	function fillProviderAddresses(providers) {
		const providersWithAddresses = providers.filter(provider => provider.addressID);
		const providersWithoutAddresses = providers.filter(provider => !provider.addressID);

		if (!providersWithAddresses?.length) {
			fillProvidersGrid(providers);
		}

		return $q.all(providersWithAddresses.map(provider => addressService.getAddressById(provider.addressID)))
			.then(results => {
				const addresses = results.map(result => result.data);
				const providersWithMappedAddresses = providersWithAddresses.map(provider => {
					const { id, ...matchingAddress } = addresses.find(address => address.id === provider.addressID);
					return Object.assign(provider, matchingAddress);
				});

				const allProviders = [...providersWithoutAddresses, ...providersWithMappedAddresses]
				return fillProvidersGrid(allProviders);
			});
	}

	function fillProvidersGrid(providers) {
		vm.claims.providers = providers;
		vm.gridOptionsProviders.data = gridUtils.populateRowId(vm.claims.providers);
		angular.copy(vm.gridOptionsProviders?.data, dataReferences.gridOptionsProviders);
	}

	function getTransactionData(id) {
		return bankingService.getTransactionsByClaimId(id).then(({ data: transactions }) => {
			assignTransactions(transactions);
		});
	}

	function assignTransactions(transactions) {
		vm.claims.operationalTransactionIds = transactions
			.filter(transaction => transaction.transferType.toLowerCase() === transactionType.Operational)
			.map(transaction => transaction.id);
	}

	function getReissuedTransactionData(id) {
		bankingService.getTransactionsByClaimId(id).then(({ data: reissueTransactions }) => {
			reissueTransactions = _.sortBy(reissueTransactions, (reissueTransaction) => -reissueTransaction.id);
			reissueTransactions = reissueTransactions.filter(reissueTransaction => reissueTransaction.transferType.toLowerCase() === transactionType.OperationalReissue);
			if (!reissueTransactions?.length) return;
			vm.claims.reissueTransactionId = reissueTransactions[0].id;
			vm.claims.reissueTransactionDate = reissueTransactions[0].transactionDate;
		});
	}

	function getNonRedeemedClaimData(id) {
		return bankingService.getReissueTransactionsByClaimId([id]).then(({ data: nonRedeemedClaims }) => {
			if (!nonRedeemedClaims?.length) return;
			nonRedeemedClaims = nonRedeemedClaims.filter(nonRedeemedClaim => nonRedeemedClaim.status.toLowerCase() === 'reissued').length === nonRedeemedClaims.length;
			if (!nonRedeemedClaims) return;
			getReissuedTransactionData(id);
		});
	}

	function getDocumentData(claim) {
		return documentService.fillClaimDocumentData(claim);
	}

	function getFileData(id) {
		return documentService.getFilesByClaimId(id).then(({ data: files }) => vm.claims.remittanceVendor = getRemittanceVendorName(files));
	}

	function getRemittanceVendorName(files) {
		if (!files?.length) return;

		let remittanceVendor;

		const { fileType: remittanceVendor1 } = files.find(file => file.fileType.toLowerCase() === 'remittance') || {};
		if (remittanceVendor1)
			remittanceVendor = 'Change Healthcare';

		const { fileType: remittanceVendor2 } = files.find(file => file.fileType.match(/wcedi/gi)) || {};
		if (remittanceVendor2)
			remittanceVendor = 'WCEDI';

		return remittanceVendor;
	}

	function getStopPaymentData(id) {
		return claimService.stopPaymentRequests_Get(null, null, id).then(({ Data: stopPayments }) => {
			assignStopPayments(stopPayments);
		});
	}

	function assignStopPayments(stopPayments) {
		if (!stopPayments?.length) return;

		vm.stopPaymentButtonDisabled = true;
		vm.stopPaymentData = stopPayments;
	}

	function getNoteData(id) {
		return notesService.getClaimNotes(id).then(({ Data: notes }) => {
			fillNotesGrid(notes);
		});
	}

	function fillNotesGrid(notes) {
		vm.gridOptionsNotes.data = gridUtils.populateRowId(notes);
		angular.copy(vm.gridOptionsNotes?.data, dataReferences.gridOptionsNotes);
	}

	function getHistoryData(id) {
		return claimService.getClaimHistory(id).then(({ Data: history }) => {
			assignHistory(history);
		});
	}

	function assignHistory(history) {
		vm.gridOptionsClaimHistory.data = replaceRedundantOdgAssignments(history);
		vm.gridOptionsClaimHistory.totalItems = history?.length;
		vm.gridOptionsClaimHistory.minRowsToShow = vm.gridOptionsClaimHistory?.data?.length + 2;
	}

	function getBillReviewReprocessData(claimId) {
		return billReviewService.getBillReviewReprocessesByClaimId(claimId).then(({ data }) => {
			vm.currentBillReviewReprocess = normalizeBillReviewReprocess(
				data.find(x => x.status === billReviewReprocessStatus.Active)
			);

			angular.copy(vm.currentBillReviewReprocess, dataReferences.currentBillReviewReprocess);

			return fillBillReviewReprocessGrid(data);
		});
	}

	function normalizeBillReviewReprocess(reprocess) {
		if (!reprocess) return null;

		reprocess.reasonId = detectBillReviewReprocessReasonId(reprocess.reason);

		if (reprocess.reasonId == vm.customBillReviewReprocessReasonId) {
			reprocess.customReason = reprocess.reason.slice(customBillReviewReprocessReasonPrefix.length);
		}

		return reprocess;
	}

	function detectBillReviewReprocessReasonId(reasonText) {
		if (!reasonText) return null;

		const reason = vm.billReviewReprocessReasons.find(x => x.value == reasonText);

		if (reason) return reason.id;

		return vm.customBillReviewReprocessReasonId;
	}

	function fillBillReviewReprocessGrid(billReviewReprocesses) {
		vm.gridOptionsBillReviewReprocess.data = billReviewReprocesses.sort((a, b) => {
			if (!b.createdDate) return -1;
			if (!a.createdDate) return 1;

			let aDate = Date.parse(a.createdDate);
			let bDate = Date.parse(b.createdDate);

			return bDate - aDate;
		});

		return vm.gridOptionsBillReviewReprocess.data;
	}

	function getRuleResultsData(id) {
		return claimService.getRuleResults(id).then(({ Data: ruleResults }) => {
			originalRuleResults = ruleResults;

			renderRuleResults();
		});
	}

	function renderRuleResults() {
		const resultsToShow = vm.showAllRuleResults
			? originalRuleResults
			: filterOutPassedUncheckedRuleResults(originalRuleResults);

		assignRuleResults(resultsToShow);
	}

	function assignRuleResults(ruleResults) {
		vm.gridOptionsRuleResults.data = sortRuleResults(ruleResults);
		vm.gridOptionsRuleResults.totalItems = ruleResults?.length;
		vm.gridOptionsRuleResults.minRowsToShow = vm.gridOptionsRuleResults?.data?.length + 2;
	}

	function sortRuleResults(ruleResults) {
		return ruleResults.map(result => {
			if (result.IsPassed) {
				result.order = ruleResultsEnum.Passed;
			} else if (!result.IsPassed && !result.ClaimId) {
				result.order = ruleResultsEnum.NotChecked;
			} else {
				result.order = ruleResultsEnum.Failed;
			}

			return result;
		}).sort((a, b) => a.order - b.order);
	}

	function getHierarchyData(id) {
		return claimService.getClaimHierarchy(id).then(({ Data: hierarchy }) => {
			vm.hierarchy = hierarchy;
			vm.claims.parentClaimId = getParentClaimId(hierarchy);
			dataReferences.parentClaimId = getParentClaimId(hierarchy);
		});
	}

	function getParentClaimId(hierarchy) {
		if (!hierarchy?.length) return;

		const { ParentClaimId } = hierarchy?.find(claim => claim?.ParentClaimId && claim?.ChildClaimId === vm.claims.id) || {};
		return ParentClaimId;
	}

	function isShowBillReviewRulesIndicator() {
		return vm.gridOptionsBillReviewRuleResults?.data?.findIndex((a) => { return !a.IsPassed && a.ClaimId; }) != -1;
	}

	function getBillReviewRuleResultsData(id) {
		return claimService.getBillReviewRuleResults(id).then(({ data: ruleResults }) => {
			originalBillReviewRuleResults = ruleResults;

			renderBillReviewRuleResults();
		});
	}

	function renderBillReviewRuleResults() {
		const resultsToShow = vm.showAllBillReviewRuleResults
			? originalBillReviewRuleResults
			: filterOutPassedUncheckedRuleResults(originalBillReviewRuleResults);

		assignBillReviewRuleResults(resultsToShow);
	}

	function assignBillReviewRuleResults(ruleResults) {
		vm.gridOptionsBillReviewRuleResults.data = ruleResults;
		vm.gridOptionsBillReviewRuleResults.totalItems = ruleResults?.length;
		vm.gridOptionsBillReviewRuleResults.minRowsToShow = vm.gridOptionsBillReviewRuleResults?.data?.length + 2;
	}

	function getPayeeData({ payeeID, payeeAddressID }) {
		return $q.all([
			payeeService.getPayeesByIds({ ids: [payeeID] }),
			payeeService.getMappingIdsByPayeeAddressId(payeeAddressID)
		]).then(results => {
			vm.claims.payee = results[0].data[0];
			return addressService.getAddressById(results[1].data.addressID);
		}).then(({ data: address }) => {
			vm.claims.payee.address = address;

			//Update data references
			dataReferences.gridOptionsPayeeData = getPayeeDataFields(vm.claims.payee);
		});
	}

	function updateSavings() {
		vm.claims.payableAmount = vm.claims.billedAmount - vm.claims.savingsAmount;
	}

	function getPPONetworkData(networkId) {
		if (!networkId) return;

		return providerService.getPPONetworkById(networkId).then(({ data: { name } }) => vm.claims.ppoNetworkName = name);
	}

	function getMemberData(id) {
		return memberService.getById(id).then(({ Data: member }) => {
			assignMember(member);
			getSharepointDocumentData();
			return $q.when(true);
		});
	}

	function assignMember(member) {
		member.accountStatus = getMemberAccountStatus(member?.AccountStatusID);
		member.allocationType = getMemberAllocationType(member?.AllocationTypeId);
		member.fullSSN = member?.SSN;
		member.SSN = getMemberTrimmedSSN(member?.SSN);

		vm.member = Object.assign(vm.member, member);
	}

	function getMemberAccountStatus(id) {
		const { DataSetValue: accountStatus } = vm.lookupdata.accountstatus.find(item => item.DataSetID === id);
		return accountStatus;
	}

	function getMemberAllocationType(id) {
		const { DataSetValue: allocationType } = vm.lookupdata.fundtype.find(item => item.DataSetID === id);
		return allocationType;
	}

	function getMemberTrimmedSSN(ssn) {
		if (!ssn) return;

		const endDigitsToShow = 4
		return `XXX-XX-` + ssn?.substr(-endDigitsToShow);
	}

	function getMemberPayeeData(memberId) {
		return payeeService.getPayeeByMemberId(memberId).then(payee => {
			if (!payee) return;

			vm.member.payee = payee;
			setPayMember();
		});
	}

	function setPayMember() {
		if (vm.member?.payee?.id === vm.claims?.payee?.id) {
			vm.payMember = true;
		}
	}

	function getMemberMailingAddress(memberId) {
		return addressService.getAddressesByMemberId(memberId).then(({ data: addresses }) => {
			return addresses.find(address => address.typeID === addressType.Mailing);
		});
	}

	function getMemberDocumentsData(memberId) {
		vm.filterData.claimId = vm.filterDocumentsByClaimId == "1" ? vm.claims.id : null;
		return documentService.getMemberDocuments({ memberId, ...vm.filterData, ...docsGridSearchParams, pageNumber: docsGridSearchParams.PageIndex }).then(({ data: documents }) => {
			assignMemberDocuments(documents);
		});
	}

	function assignMemberDocuments(documents) {
		vm.gridOptionsDocs.data = (documents.items || []).map(x => { x.documentName = x.physicalPath; return x; });
		vm.gridOptionsDocs.totalItems = documents.totalRows || 0;
		vm.gridOptionsDocs.minRowsToShow = (documents.items?.length || 0) + 2;
	}

	function getMemberBenefitsData(memberId) {
		return benefitsService.filterBenefits({ MemberID: memberId, ...searchParamsForBenefits }).then((benefits) => {
			assignMemberBenefits(benefits);
		});
	}

	function assignMemberBenefits(benefits) {
		vm.gridOptionsBenefits.data = benefits;
		vm.gridOptionsBenefits.totalItems = benefits[0]?.TotalRows;
		vm.gridOptionsBenefits.minRowsToShow = vm.gridOptionsBenefits?.data?.length + 2;
	}

	function isShowHoldAmountsIndicator() {
		return vm.gridOptionsHoldAmounts?.data?.length > 0;
	}

	function getMemberHoldData(memberId) {
		return memberService.getMemberHoldsByMemberId(memberId).then(({ data: holds }) => {
			const activeMemberHolds = holds.filter(hold => !hold.clearDate);
			assignMemberHolds(activeMemberHolds);
		});
	}

	function deleteHoldAmountRow(holds) {

		vm.isDataLoading = true;

		return memberService.clearMemberHold(holds.id).then(() => {
			return getMemberHoldData(vm.member.MemberID);
		}).finally(_ => vm.isDataLoading = false);
	}

	function canHoldAmountBeDeleted(holds) {
		if (!holds.HoldID) return true;

		return hasRole('CareGuardFinance', 'Add-Clear-MemberHoldAmount');
	}

	function assignMemberHolds(holds) {
		vm.gridOptionsHoldAmounts.data = holds;
		vm.gridOptionsHoldAmounts.totalItems = holds[0]?.TotalRows;
		vm.gridOptionsHoldAmounts.minRowsToShow = vm.gridOptionsHoldAmounts?.data?.length + 2;
	}

	function getMemberFlagData(memberId) {
		return memberFlagsService.getMemberFlags(memberId).then(({ Data: flags }) => vm.memberFlags = flags);
	}

	function getSharepointDocumentData() {
		const { MemberNumber: memberNumber, FirstName: firstName, LastName: lastName } = vm.member;
		vm.isSharepointDocumentsLoaded = false;
		return sharepointDocumentService.getSharepointDocuments({ memberNumber, firstName, lastName, paging: { ...sharepointDocsGridSearchParams } }, { ignoreLoadingBar: true })
			.then(({ data: { Rows: documents }, data: { TotalRows: totalRows } }) => {
				assignSharepointDocuments({ documents, totalRows });
				vm.isSharepointDocumentsLoaded = true;
			});
	}

	function assignSharepointDocuments({ documents, totalRows }) {
		vm.gridOptionsSharepointDocs.data = documents;
		vm.gridOptionsSharepointDocs.totalItems = totalRows;
		vm.gridOptionsSharepointDocs.minRowsToShow = vm.gridOptionsSharepointDocs?.data?.length + 2;
	}

	function getUrlFromState(stateName, params) {
		return getUrlService.get(stateName, params);
	}

	function isEditParentClaimAvailable() {
		return (vm.isEditParentClaimOpen && !isDispositionStandard()) || vm.isClaimInPaidStatus();
	}

	function saveNewParentClaim() {
		if (!vm.selectedNewParentClaim) {
			$toastr.show('Please select claim or cancel.', 'warning');
			return;
		}

		vm.newParentClaim = null;
		vm.claims.parentClaimId = vm.selectedNewParentClaim.ClaimID;
		vm.switchEditParentClaim();
		vm.selectedNewParentClaim = null;
	}

	function clearParentClaim() {
		vm.claims.parentClaimId = null;
	}

	function switchEditParentClaim() {
		vm.isEditParentClaimOpen = !vm.isEditParentClaimOpen;
	}

	function selectParentClaim(claim) {
		if (claim) {
			vm.selectedNewParentClaim = claim.originalObject;
		}
	}

	function switchEditTaxId() {
		vm.newPayeeTaxID = null;
		vm.isEditTaxIdOpen = !vm.isEditTaxIdOpen;
	}

	function setNewPayee({ originalObject: payee }) {
		if (!payee.taxID) {
			$toastr.show('There are no payees matching this tax ID. Please enter a valid one.', 'warning');
			return;
		}

		addressService.getAddressesByPayeeId(payee.id).then(({ data: addresses }) => {
			vm.payMember = false;
			vm.isEditTaxIdOpen = false;
			vm.claims.pendingPayeeSelect = payee;
			vm.claims.payee.availableAddresses = addresses;

			LxDialogService.open(payeeAddressSelectionPopupId);
		});
	}

	vm.selectPayeeAddress = (address) => {
		vm.claims.payee = vm.claims.pendingPayeeSelect;
		vm.claims.payee.address = address;
		vm.claims.payee.availableAddresses = [];
		LxDialogService.close(payeeAddressSelectionPopupId);
	};

	vm.cancelPayeeAddressSelect = () => {
		vm.claims.pendingPayeeSelect = {};
		LxDialogService.close(payeeAddressSelectionPopupId);
	};

	function isEditTaxIdAvailable() {
		return vm.isEditTaxIdOpen && !vm.isClaimInPaidStatus();
	}

	function isPayeeChangeAllowed() {
		return !vm.isEditTaxIdOpen
			&& !vm.payMember
			&& !vm.isClaimInPaidStatus()
			&& vm.hasRole('ClaimActions');
	}

	vm.hasProvidersGridChanged = function () {
		return gridUtils.hasGridChanged(vm.gridOptionsProviders?.data, dataReferences.gridOptionsProviders);
	};

	vm.hasNotesGridChanged = () => vm.gridOptionsNotes?.data && vm.gridOptionsNotes?.data.some(x => x.isEdit);

	function isDispositionStandard() {
		return vm.claims.dispositionTypeID === dispositionStatusId.Standard;
	}

	function getClaimHierarchyMargin(claimConnectionLevel) {
		return claimConnectionLevel * 30 + 'px';
	}
	function isCurrentHierarchyClaim(claimId) {
		return claimId === vm.claims.id;
	}

	function getBillReviewRuleResultsGridHeight() {
		const lastExpectedIndex = vm.gridOptionsBillReviewRuleResults.paginationCurrentPage * vm.gridOptionsBillReviewRuleResults.paginationPageSize;

		const rowsOnPage = vm.gridOptionsRuleResults?.data?.length >= lastExpectedIndex
			? vm.gridOptionsBillReviewRuleResults.paginationPageSize
			: vm.gridOptionsBillReviewRuleResults?.data?.length % vm.gridOptionsBillReviewRuleResults.paginationPageSize;
		return (rowsOnPage + 2) * vm.gridOptionsBillReviewRuleResults.rowHeight + 'px';
	}

	function getDataForClaimReductions(claimId) {
		if (!vm.claims.billReview || !vm.claims.billReview.billReviewReceivedDate) {
			return $q.when(null);
		}

		return claimService.getDataForClaimReductions(claimId).then(res => {
			if (!res?.data?.length) {
				return;
			}

			var arrByResultId = [];

			vm.claimLineReductions.splice(0, vm.claimLineReductions.length);
			res.data.forEach(item => {
				var vendorReductions = arrByResultId[item.BillReviewResultId];
				if (!vendorReductions) {

					var billReviewVendorName = utilService.getKeyByValue(billReviewVendors, item.BillReviewVendorId);
					var reductionName = utilService.getKeyByValue(billReviewReductionTypes, item.ReductionType);

					vendorReductions = {
						BillReviewResultId: item.BillReviewResultId,
						BillReviewVendorId: item.BillReviewVendorId,
						BillReviewVendorName: `${billReviewVendorName} (${reductionName})`,
						Lines: [item]
					};
					arrByResultId[item.BillReviewResultId] = vendorReductions;
				}
				else {
					vendorReductions.Lines.push(item);
				}

			});

			for (var p in arrByResultId) {
				if (arrByResultId.hasOwnProperty(p)) {
					vm.claimLineReductions.push(arrByResultId[p]);
				}
			}
		});
	}

	function getBillReviewInstance(claimId) {

		return billReviewWorkflowService.getBillReviewWorkflowInstance(claimId)
			.then(brInstance => {
				vm.claims.billReview = brInstance;

				if (vm.claims.billReview.billReviewSentDate) {
					vm.claims.billReview.billReviewSentDate = utilService.formatUtcToEst(vm.claims.billReview.billReviewSentDate, 'MM/DD/YYYY');
				}

				if (vm.claims.billReview.billReviewReceivedDate) {
					vm.claims.billReview.billReviewReceivedDate = utilService.formatUtcToEst(vm.claims.billReview.billReviewReceivedDate, 'MM/DD/YYYY');
				}
			}).catch(_ => {
				vm.claims.billReview = null;
			});
	}

	function replaceRedundantOdgAssignments(claimHistory) {
		let updatedClaimHistory = [],
			lastOdgAssignmentDate = null,
			lastOdgAssignment = null,
			countOfOdgAssignmentsPerDay = 0;

		for (let i = 0; i < claimHistory?.length; i++) {

			// Save an item that is not ODG assignment
			if (claimHistory[i].UpdatedBy !== 'Job-ODG') {
				updatedClaimHistory.push(claimHistory[i]);

				// Save the last ODG assignment from the previous sequence, if collection ends with non-ODG item
				if (
					i === claimHistory?.length - 1 &&
					countOfOdgAssignmentsPerDay > 1 &&
					lastOdgAssignment
				) {
					updatedClaimHistory.push(lastOdgAssignment);
				}

			} else {
				let odgAssignmentDate = new Date(claimHistory[i].UpdatedDate);
				odgAssignmentDate.setHours(0, 0, 0, 0);

				// If an item is the first ODG assignment per day or the last element in collection
				if (
					!lastOdgAssignmentDate ||
					lastOdgAssignmentDate.getTime() !== odgAssignmentDate.getTime() ||
					i === claimHistory?.length - 1
				) {

					// Save the last ODG assignment for the previous day
					if (lastOdgAssignment &&
						lastOdgAssignmentDate.getTime() !== odgAssignmentDate.getTime() &&
						countOfOdgAssignmentsPerDay > 1
					) {
						updatedClaimHistory.push(lastOdgAssignment);
					}

					// Save the first ODG assignment for the current day or the last element in collection
					updatedClaimHistory.push(claimHistory[i]);

					// Move lastOdgAssignment cursor, update lastOdgAssignmentDate and sequence counter
					lastOdgAssignmentDate = odgAssignmentDate;
					lastOdgAssignment = claimHistory[i];
					countOfOdgAssignmentsPerDay = 1;

				} else {

					// Move lastOdgAssignment cursor and update sequence counter
					lastOdgAssignment = claimHistory[i];
					countOfOdgAssignmentsPerDay++;
				}
			}
		}

		return updatedClaimHistory;
	}

	vm.gridOptionsProviders = defaultGridOptions({
		data: [],
		columnDefs: providersDefsGrid,
		rowHeight: 48,
		headerHeight: 48,
		minRowsToShow: 4,
		rowTemplate,
		enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
		enablePaginationControls: false,
		enableSorting: false,
		enableCellEdit: false,
		enableCellEditOnFocus: true,
		useExternalPagination: false,
		useExternalSorting: false,
		totalItems: 0,
		enableExpandableRowHeader: false,
		gridFooterTemplate: providersFooterTemplate,
		showGridFooter: true
	});

	vm.gridOptionsProviders.cellEditableCondition = ($scope) => $scope.row.entity.isEditable;

	vm.gridOptionsNotes = defaultGridOptions({
		data: [],
		columnDefs: notesDefsGrid,
		rowHeight: 80,
		headerHeight: 60,
		minRowsToShow: 4,
		rowTemplate,
		enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
		enableSorting: true,
		enableCellEditOnFocus: true,
		enablePaginationControls: false,
		enableExpandableRowHeader: false,
		useExternalPagination: false,
		gridFooterTemplate: notesFooterTemplate,
		showGridFooter: true,
		onRegisterApi: notesGridOnRegisterApi
	});

	function notesGridOnRegisterApi(gridApi) {
		gridApi.core.on.sortChanged($scope, notesGridSortChanged);
		setDefaultSortForGrid(gridApi, notesGridSearchParams);
	}

	function notesGridSortChanged(grid, sortColumns) {
		if (!sortColumns?.length || !sortColumns[0].sort) return;

		notesGridSearchParams.SortOrder = sortColumns[0].sort.direction.toUpperCase();
		notesGridSearchParams.SortColumn = sortColumns[0].field;

		sortNotes({ data: vm.gridOptionsNotes?.data, key: notesGridSearchParams.SortColumn, order: notesGridSearchParams.SortOrder });
		fillNotesGrid(vm.gridOptionsNotes?.data);
	}

	function sortNotes({ data, key, order }) {
		_.sortBy(data, [key], [order]);
	}

	function setDefaultSortForGrid(gridApi, searchParams) {
		if (!searchParams.SortOrder || !searchParams.SortColumn) return;

		const sortColumn = gridApi.grid.options.columnDefs.find(col => col.field.toUpperCase() ===
			searchParams.SortColumn.toUpperCase());

		if (!sortColumn) return;

		sortColumn.sort = {
			direction: searchParams.SortOrder.toUpperCase() === 'ASC' ? uiGridConstants.ASC : uiGridConstants.DESC
		};
	}

	vm.gridOptionsDocs = defaultGridOptions({
		data: [],
		columnDefs: docsDefsGrid,
		rowHeight: 48,
		headerHeight: 48,
		minRowsToShow: 4,
		enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
		rowTemplate,
		enablePaginationControls: true,
		enablePagination: true,
		enableSorting: true,
		useExternalSorting: true,
		useExternalPagination: true,
		enableCellEdit: false,
		paginationPageSize: docsGridSearchParams.PageSize,
		totalItems: 0,
		enableExpandableRowHeader: false,
		onRegisterApi: docsGridOnRegisterApi
	});

	vm.getDocsGridHeight = () => (vm.gridOptionsDocs?.data?.length + 2) * vm.gridOptionsDocs.rowHeight + 'px';

	function docsGridOnRegisterApi(gridApi) {
		gridApi.pagination.on.paginationChanged($scope, docsGridPaginationChanged);
		gridApi.core.on.sortChanged($scope, docsGridSortChanged);
	}

	vm.gridOptionsSharepointDocs = defaultGridOptions({
		data: [],
		columnDefs: sharepointDocsDefsGrid,
		rowHeight: 48,
		headerHeight: 48,
		minRowsToShow: 4,
		enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
		rowTemplate,
		enablePaginationControls: true,
		enablePagination: true,
		enableSorting: true,
		useExternalSorting: true,
		useExternalPagination: true,
		enableCellEdit: false,
		paginationPageSize: sharepointDocsGridSearchParams.PageSize,
		totalItems: 0,
		enableExpandableRowHeader: false,
		onRegisterApi: sharepointDocsGridOnRegisterApi
	});

	function sharepointDocsGridOnRegisterApi(gridApi) {
		gridApi.pagination.on.paginationChanged($scope, sharepointDocsGridPaginationChanged);
		gridApi.core.on.sortChanged($scope, sharepointDocsGridSortChanged);
		if (!vm.gridSharepointPaginationApi) {
			vm.gridSharepointPaginationApi = gridApi.pagination;
		}
	}

	vm.getSharepointDocsGridHeight = () => (vm.gridOptionsSharepointDocs?.data?.length + 2) * vm.gridOptionsSharepointDocs.rowHeight + 'px';

	function openZeroPayDialog() {
		if (vm.isClaimInPaidStatus()) {
			$toastr.show('You can not Zero Pay Claim with \'' + vm.claims.claimStatus + '\' Claim Status.', 'warning');
			return;
		}

		LxDialogService.open(zeroPayDialogId);
	}

	function openCloneDialog() {
		const normalClone = c => true;
		const liabilityClone = c => c.Value == claimCloneTypeEnum.ManuallyUpdate;

		vm.claimCloneTypeOptions = claimCloneTypeEnum.Names.filter(billReviewIsLiability() ?
			liabilityClone : normalClone);

		vm.claimCloneTypeId = null;
		LxDialogService.open(openCloneDialogId);
	}

	function closeCloneDialog() {
		LxDialogService.close(openCloneDialogId);
	}

	function completeZeroPay() {
		if (!vm.newClaimDetailStatusID) {
			$toastr.show('Please select Claim Detail Status before complete.', 'warning');
			return;
		}

		vm.isDataLoading = true;
		claimService.zeroPay({ IDList: [vm.claims.id], newClaimDetailStatusID: vm.newClaimDetailStatusID }).then(deleteEORDocument)
			.then(loadClaim).finally(_ => {
				vm.newClaimDetailStatusID = null;
				vm.isDataLoading = false;
				LxDialogService.close(zeroPayDialogId);
			});
	}

	function deleteEORDocument() {
		const eorData = getExistingEorData();

		if (!eorData) return $q.when(null);

		return billReviewService.setCurrentEor(vm.claims.id, eorData.documentId, false)
			.then(() => {
				return getDocumentData(vm.claims);
			});
	}

	function docsGridPaginationChanged(newPage, pageSize) {
		docsGridSearchParams.PageSize = pageSize;
		docsGridSearchParams.PageIndex = newPage;

		getMemberDocumentsData(vm.claims.memberID);
	}

	function docsGridSortChanged(grid, sortColumns) {
		if (!sortColumns?.length || !sortColumns[0].sort) {
			return;
		}

		grid.api.pagination.seek(1);
		docsGridSearchParams.PageIndex = 1;

		docsGridSearchParams.SortOrder = sortColumns[0].sort.direction.toUpperCase();
		docsGridSearchParams.SortColumn = sortColumns[0].field;

		getMemberDocumentsData(vm.claims.memberID);
	}

	function sharepointDocsGridPaginationChanged(newPage, pageSize) {
		sharepointDocsGridSearchParams.PageSize = pageSize;
		sharepointDocsGridSearchParams.PageIndex = newPage;

		getSharepointDocumentData();
	}

	function sharepointDocsGridSortChanged(grid, sortColumns) {
		if (!sortColumns?.length || !sortColumns[0].sort) {
			return;
		}

		vm.gridSharepointPaginationApi.seek(1);
		sharepointDocsGridSearchParams.PageIndex = 1;

		sharepointDocsGridSearchParams.SortOrder = sortColumns[0].sort.direction.toUpperCase();
		sharepointDocsGridSearchParams.SortColumn = sortColumns[0].field;

		getSharepointDocumentData();
	}

	function getDocumentFile(fileNameWithExt) {
		return documentService.downloadMemberAzureBlobDocument({ memberNumber: vm.member.MemberNumber, filename: fileNameWithExt }).then(response => {
			if (Array.isArray(response?.data) && !response?.data?.length) {
				$toastr.show(`Document not found.`, `warning`);
				return;
			}
			utilService.processResponse({ response });
		});
	}

	vm.gridOptionsBenefits = defaultGridOptions({
		data: [],
		columnDefs: benefitsDefsGrid,
		rowHeight: 48,
		headerHeight: 60,
		minRowsToShow: 4,
		enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
		rowTemplate,
		enableCellEdit: false,
		enablePaginationControls: true,
		enablePagination: true,
		enableSorting: false,
		useExternalPagination: true,
		paginationPageSize: searchParamsForBenefits.PageSize,
		totalItems: 0,
		enableExpandableRowHeader: false,
		onRegisterApi: benefitsGridOnRegisterApi
	});

	function benefitsGridOnRegisterApi(gridApi) {
		gridApi.pagination.on.paginationChanged($scope, benefitsGridPaginationChanged);
	}

	vm.getBenefitsGridHeight = () => (vm.gridOptionsBenefits?.data?.length + 2) * vm.gridOptionsBenefits.rowHeight + 'px';

	function benefitsGridPaginationChanged(newPage, pageSize) {
		searchParamsForBenefits.PageSize = pageSize;
		searchParamsForBenefits.PageIndex = newPage;
		getMemberBenefitsData(vm.claims.memberID);
	}

	vm.gridOptionsHoldAmounts = defaultGridOptions({
		data: [],
		columnDefs: holdAmountsDefsGrid,
		rowHeight: 48,
		headerHeight: 48,
		minRowsToShow: 4,
		enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
		rowTemplate,
		enableCellEdit: false,
		enablePaginationControls: true,
		enablePagination: true,
		enableSorting: false,
		useExternalPagination: true,
		paginationPageSize: 10,
		totalItems: 0,
		enableExpandableRowHeader: false
	});

	vm.getHoldAmountsGridHeight = () => (vm.gridOptionsHoldAmounts?.data?.length + 2) * vm.gridOptionsHoldAmounts.rowHeight + 'px';

	vm.gridOptionsClaimHistory = defaultGridOptions({
		data: [],
		columnDefs: claimHistoryDefsGrid,
		rowHeight: 48,
		headerHeight: 48,
		minRowsToShow: 4,
		enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
		rowTemplate,
		enableCellEdit: false,
		enablePaginationControls: true,
		enablePagination: true,
		enableSorting: false,
		useExternalPagination: false,
		paginationPageSize: 10,
		totalItems: 0,
		enableExpandableRowHeader: false
	});

	vm.gridOptionsBillReviewReprocess = defaultGridOptions({
		data: [],
		columnDefs: billReviewReprocessColumnDefs,
		rowHeight: 48,
		headerHeight: 48,
		minRowsToShow: 2,
		enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
		rowTemplate,
		enableCellEdit: false,
		enablePaginationControls: true,
		enablePagination: true,
		enableSorting: false,
		useExternalPagination: false,
		paginationPageSize: 10,
		totalItems: 0,
		enableExpandableRowHeader: false
	});

	vm.gridOptionsRuleResults = defaultGridOptions({
		data: [],
		columnDefs: ruleResultsDefsGrid,
		rowHeight: 52,
		headerHeight: 52,
		minRowsToShow: 4,
		enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
		rowTemplate,
		enableCellEdit: false,
		enablePaginationControls: false,
		enablePagination: false,
		enableSorting: true,
		useExternalPagination: false,
		totalItems: 0,
		enableExpandableRowHeader: false,
		defaultSort: { direction: uiGridConstants.DESC },
		gridFooterTemplate: ruleResultsFooterTemplate,
		showGridFooter: true
	});

	vm.gridOptionsRuleResults.columnDefs.forEach(item => item.sortDirectionCycle = [uiGridConstants.ASC, uiGridConstants.DESC]);

	vm.gridOptionsBillReviewRuleResults = defaultGridOptions({
		data: [],
		columnDefs: billReviewRuleResultsColumnDefs,
		rowHeight: 48,
		headerHeight: 48,
		minRowsToShow: 4,
		enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
		rowTemplate,
		enableCellEdit: false,
		enablePaginationControls: false,
		enablePagination: false,
		enableSorting: true,
		useExternalPagination: false,
		totalItems: 0,
		enableExpandableRowHeader: false,
		defaultSort: { direction: uiGridConstants.DESC },
		gridFooterTemplate: billReviewRuleResultsFooterTemplate,
		showGridFooter: true
	});

	vm.gridOptionsBillReviewRuleResults.columnDefs.forEach(item => item.sortDirectionCycle = [uiGridConstants.ASC, uiGridConstants.DESC]);

	function getClaimHistoryGridHeight() {
		const lastExpectedIndex = vm.gridOptionsClaimHistory.paginationCurrentPage * vm.gridOptionsClaimHistory.paginationPageSize;

		const rowsOnPage = vm.gridOptionsClaimHistory?.data?.length >= lastExpectedIndex
			? vm.gridOptionsClaimHistory.paginationPageSize
			: vm.gridOptionsClaimHistory?.data?.length % vm.gridOptionsClaimHistory.paginationPageSize;
		return (rowsOnPage + 2) * vm.gridOptionsClaimHistory.rowHeight + 'px';
	}

	function getBillReviewReprocessGridHeight() {
		const lastExpectedIndex = vm.gridOptionsBillReviewReprocess.paginationCurrentPage *
			vm.gridOptionsBillReviewReprocess.paginationPageSize;

		const rowsOnPage = vm.gridOptionsBillReviewReprocess?.data?.length >= lastExpectedIndex
			? vm.gridOptionsBillReviewReprocess.paginationPageSize
			: vm.gridOptionsBillReviewReprocess?.data?.length % vm.gridOptionsBillReviewReprocess.paginationPageSize;

		return (rowsOnPage + 2) * vm.gridOptionsBillReviewReprocess.rowHeight + "px";
	}

	function isShowRulesIndicator() {
		return vm.gridOptionsRuleResults?.data?.findIndex((a) => { return !a.IsPassed && a.ClaimId; }) != -1;
	}

	function getRuleResultsGridHeight() {
		const lastExpectedIndex = vm.gridOptionsRuleResults.paginationCurrentPage * vm.gridOptionsRuleResults.paginationPageSize;

		const rowsOnPage = vm.gridOptionsRuleResults?.data?.length >= lastExpectedIndex
			? vm.gridOptionsRuleResults.paginationPageSize
			: vm.gridOptionsRuleResults?.data?.length % vm.gridOptionsRuleResults.paginationPageSize;
		return (rowsOnPage + 2) * vm.gridOptionsRuleResults.rowHeight + 'px';
	}

	function isShowNotesIndicator() {
		return vm.gridOptionsNotes?.data?.findIndex((a) => { return !a.isNewNote; }) != -1;
	}

	function addNotesRow() {
		++vm.gridOptionsNotes.minRowsToShow;

		const emptyRow = gridUtils.getEmptyRowEntityFromColumnDefs(notesDefsGrid);
		emptyRow.rowId = gridUtils.generateRowId(vm.gridOptionsNotes?.data);
		emptyRow.MemberId = vm.claims.memberID;
		emptyRow.ClaimID = vm.claims.id;
		emptyRow.FollowUp = defaultFollowupStatus;
		emptyRow.NoteType = defaultNotesType;
		emptyRow.CreatedDate = new Date();
		emptyRow.UpdateDate = emptyRow.CreatedDate;
		emptyRow.isEdit = true;
		emptyRow.isNewNote = true;

		vm.gridOptionsNotes?.data.push(emptyRow);
	}

	function deleteNotesRow(note) {
		vm.gridOptionsNotes.data = vm.gridOptionsNotes.data.filter(noteData => noteData.rowId !== note.rowId);

		if (!note.NoteID) {
			vm.gridOptionsNotes.data = gridUtils.populateRowId(vm.gridOptionsNotes?.data);
			return;
		}

		return notesService.deleteNote(note.NoteID).then(() => {
			angular.copy(vm.gridOptionsNotes?.data, dataReferences.gridOptionsNotes);
		});
	}

	function addNoteRow(note) {
		if (!note.NoteText) {
			$toastr.show('Enter note to create a record', 'warning');
			return;
		}

		return notesService.addNote(note).then(noteResult => {
			if (!noteResult) return;
			note = completeNote(noteResult);

			dataReferences.gridOptionsNotes.push(note);
			angular.copy(dataReferences.gridOptionsNotes, vm.gridOptionsNotes?.data);
		});
	}

	function saveNoteRow(note) {
		if (!note.NoteText) {
			$toastr.show('Enter note to create a record', 'warning');
			return;
		}

		return notesService.updateNote(note).then(noteResult => {
			if (!noteResult) return;
			note = completeNote(noteResult);

			angular.copy(vm.gridOptionsNotes?.data, dataReferences.gridOptionsNotes);
		});
	}

	function completeNote(note) {
		note.isEdit = false;
		note.isNewNote = false;
		return note;
	}

	function addProviderRow() {
		++vm.gridOptionsProviders.minRowsToShow;

		const emptyRow = gridUtils.getEmptyRowEntityFromColumnDefs(providersDefsGrid);
		emptyRow.rowId = gridUtils.generateRowId(vm.gridOptionsProviders?.data);
		emptyRow.claimID = vm.claims.id;
		emptyRow.isEditable = true;

		vm.gridOptionsProviders?.data.push(emptyRow);
	}

	function deleteProviderRow(provider) {
		let index = vm.gridOptionsProviders?.data.findIndex(x => x.rowId === provider.rowId);
		if (index > -1) {
			vm.gridOptionsProviders?.data.splice(index, 1);
		}

		if (!provider.id) {
			vm.gridOptionsProviders.data = gridUtils.populateRowId(vm.gridOptionsProviders?.data);
			return;
		}

		vm.gridOptionsProviders.invalidFields = {};

		vm.isDataLoading = true;

		return claimService.deleteProvider(provider).then(() => {
			vm.gridOptionsProviders.data = gridUtils.populateRowId(vm.gridOptionsProviders?.data);
		}).finally(_ => vm.isDataLoading = false);
	}

	function saveProviders() {
		vm.gridOptionsProviders.invalidFields = validateProvidersGrid();

		if (!angular.equals({}, vm.gridOptionsProviders.invalidFields)) {
			$toastr.show('Please fill the required fields before saving!', 'warning');
			return;
		}

		claimService.saveClaimProviders(vm.gridOptionsProviders?.data).then((result) => {
			vm.gridOptionsProviders.data.forEach(provider => {
				if (provider.isEditable) {
					delete provider.isEditable;
				}
			});

			angular.copy(vm.gridOptionsProviders?.data, dataReferences.gridOptionsProviders);
		}).then(_ => getProviderData(claimValue));
	}

	function validateProvidersGrid() {
		let invalidFields = {},
			conditionallyRequiredPresentFields = {},
			conditionallyRequiredFieldsNames = {};

		const gridToValidate = vm.gridOptionsProviders;

		if (!gridToValidate) return;

		gridToValidate?.data.forEach((row, index) => {
			invalidFields[index + 1] = [];
			conditionallyRequiredPresentFields[index + 1] = [];
			conditionallyRequiredFieldsNames[index + 1] = [];

			for (let field in row) {
				const fieldConfig = gridToValidate.columnDefs.find(config => config.field === field);
				if (fieldConfig && fieldConfig.required && !row[field] && !row.ProviderID) {
					invalidFields[index + 1].push(fieldConfig.displayName);
				}
			}

			if (invalidFields[index + 1]?.length < 1) {
				delete invalidFields[index + 1]
			}
		});

		return invalidFields;
	}

	function cancelProvidersGridChanges() {
		if (!dataReferences.gridOptionsProviders || !dataReferences.gridOptionsProviders?.length) return;

		angular.copy(dataReferences.gridOptionsProviders, vm.gridOptionsProviders?.data);
	}

	function cancelNotesGridChanges() {
		if (!dataReferences.gridOptionsNotes || !dataReferences.gridOptionsNotes?.length) return;

		angular.copy(dataReferences.gridOptionsNotes, vm.gridOptionsNotes?.data);
	}

	function removeFees() {
		if (!removeFeeAction || !removeFeeAction.Value) return;

		vm.isDataLoading = true;
		claimService.removeFees([vm.claims.id]).then(loadClaim)
			.finally(_ => vm.isDataLoading = false);
	}

	function removeSavings() {
		const request = {
			ClaimID: vm.claims.id,
			IDList: [vm.claims.id]
		};

		vm.isDataLoading = true;
		claimService.removeSavings(request)
			.then(loadClaim)
			.finally(_ => vm.isDataLoading = false);
	}

	function isClaimInPaidStatus() {
		if (!vm.claims || !vm.claims.claimStatusID) return;

		return claimService.isStatusAPaidStatus(vm.claims.claimStatusID);
	}

	function isClaimPaidByACH() {
		if (!vm.claims || !vm.claims.claimStatusID) return;

		if (claimStatusId.PaidByACH === vm.claims.claimStatusID && !vm.claims.paidDate) {
			vm.claims.paidDate = (new Date()).toISOString();
			vm.paidDateUpdatedByStatusChange = true;
		} else if (vm.paidDateUpdatedByStatusChange) {
			vm.claims.paidDate = null;
			vm.paidDateUpdatedByStatusChange = false;
		}
	}

	function changeDispositionDisabled() {
		return !vm.canBeCloned() || !vm.hasRole('EditClaimDisposition') || vm.isClaimInPaidStatus();
	}

	function validateParentClaimShouldNotExists() {
		return dispositionStatusId.Standard === Number(vm.claims.dispositionTypeId) && vm.claims.parentClaimId;
	}

	function validateParentClaimAsChild() {
		return vm.claims.parentClaimId === vm.claims?.id;
	}

	function validateParentClaim() {
		return dispositionStatusesRequiredParentClaimId.includes(Number(vm.claims.dispositionTypeId)) && !vm.claims.parentClaimId;
	}

	function hasDiagnosisGridChanged() {
		return gridUtils.hasGridChanged(vm.claims.gridOptionsDiagnosis?.data, dataReferences.gridOptionsDiagnosis);
	}

	function isDRGRequired() {
		return vm.claims
			&& (vm.claims.billTypeID == billTypeId.UB04INPT)
			&& new RegExp(/^1(1|2)\d$/g).test(vm.claims.billTypeCode);
	}

	function isBillTypeCodeRequired() {
		const billTypesForRequiredBillTypeCode = [billTypeId.UB04INPT, billTypeId.UB04OUTPT];
		return billTypesForRequiredBillTypeCode.includes(+vm.claims.billTypeID);
	}

	function getEditableClaimHeaderFields(claim) {
		var fields = _.pick(claim, editableHeaderFields);

		fields.claimStatusID = String(fields.claimStatusID);
		fields.billEntryTypeID = String(fields.billEntryTypeID);
		fields.dispositionTypeId = String(fields.dispositionTypeId);
		fields.billTypeID = String(fields.billTypeID);
		if (!fields.reconsiderationNote) {
			fields.reconsiderationNote = null;
		}
		return fields;
	}

	function getPayeeDataFields(payeeData) {
		return _.pick(payeeData, editablePayeeFields);
	}

	function isLiabilityPartialPayValid(updatedClaim) {
		var firstLineStatusId = vm.claims.lineItems?.[0].statusID;

		if (updatedClaim.payableAmount > 0 && billReviewIsLiability() && isZeroPay(firstLineStatusId))
			return false;

		return true;
	}

	function hasSavingsAmountChanged(updatedClaimSavingsAmount) {
		return dataReferences.gridOptionsHeaderData.savingsAmount != updatedClaimSavingsAmount;
	}

	function isZeroPay(paramClaimDetailStatusId) {
		return vm.claimDetailStatuses.find(x => x.id == parseInt(paramClaimDetailStatusId)).category.isZeroPay;
	}

	function hasClaimHeaderFieldsChanged(updatedClaim) {
		var updatedClaimHeaders = getEditableClaimHeaderFields(updatedClaim);

		return !angular.equals(updatedClaimHeaders, dataReferences.gridOptionsHeaderData);
	}

	function hasPayeeDataChanged(payeeData) {
		var updatedPayee = getPayeeDataFields(payeeData);
		return !angular.equals(updatedPayee, dataReferences.gridOptionsPayeeData);
	}

	function calculateFees() {
		if (!vm.claims.gridOptionsLineItems?.data?.length) {
			$toastr.show('Please enter at least one Line Item.', 'warning');
			return;
		}
		if (hasClaimHeaderFieldsChanged(vm.claims)
			|| (vm.claims.parentClaimId !== dataReferences.parentClaimId)
			|| hasPayeeDataChanged(vm.claims.payee)
			|| gridUtils.hasGridChanged(vm.claims.lineItems, dataReferences.gridOptionsLineItems)
			|| hasDiagnosisGridChanged()
			|| vm.hasProvidersGridChanged()
			|| vm.hasNotesGridChanged()) {
			$toastr.show('You have unsaved changes. Please, save the claim before calculating the fee', 'warning');
			return;
		}

		vm.isDataLoading = true;

		return claimService.recalculateFees(vm.claims.id).then(loadClaim)
			.finally(_ => vm.isDataLoading = false);
	}

	function confirmTrustAccount() {
		vm.saveClaimOptions.skipTrustAccountConfirmation = true;
		return saveClaimResolvePopupPromise('openTrustAccountReimbursementConfirmationPopupFunction');
	}

	function completeFeeRecalculationPopup({ recalculate }) {
		vm.saveClaimOptions.skipRecalculateFeesPopup = true;
		vm.saveClaimOptions.recalculateFeesAction = recalculate;
		return saveClaimResolvePopupPromise('openFeeRecalculationPopup');
	}

	function saveClaims({
		skipTrustAccountConfirmation = false,
		skipRecalculateFeesPopup = false,
		recalculateFeesAction = false
	} = {}) {

		var rejectWithToaster = function (message, severity) {
			$toastr.show(message, severity);
			return $q.reject({
				data: message,
				severity: severity,
			});
		};

		if (!vm.claims.owner) {
			vm.claims.ownerId = null;
		}

		if (!vm.claimForm.$valid) {
			return rejectWithToaster('Please correct invalid data before saving!', 'warning');
		}

		if (vm.validateParentClaim()) {
			return rejectWithToaster('Parent claim should exist if disposition is not Standard', 'error');
		}

		if (vm.validateParentClaimShouldNotExists()) {
			return rejectWithToaster('Parent claim should not exist if disposition type is Standard.', 'error');
		}

		if (vm.validateParentClaimAsChild()) {
			return rejectWithToaster('Parent claim should not be the same as child claim.', 'error');
		}

		//Reconsideration Checkbox is marked AND Reconsideration Note text box is empty
		if (vm.claims.isReconsideration && !vm.claims.reconsiderationNote) {
			return rejectWithToaster('Reconsideration check box is marked. Reconsideration Note is required.', 'error');
		}

		//Disposition is Reconsideration or Resubmission AND Reconsideration Checkbox is not marked
		if ([dispositionStatusId.Reconsideration, dispositionStatusId.Resubmission].includes(Number(vm.claims.dispositionTypeId)) && !vm.claims.isReconsideration) {
			return rejectWithToaster('Reconsideration check box must be marked if Disposition is Reconsideration or Resubmission.', 'error');
		}

		//Disposition is not one of the valid Dispositions AND Reconsideration Checkbox is marked
		if ([dispositionStatusId.Standard, dispositionStatusId.Duplicate, dispositionStatusId.Fraud].includes(Number(vm.claims.dispositionTypeId)) && vm.claims.isReconsideration) {
			return rejectWithToaster('Reconsideration check box is marked. Disposition must be Reconsideration, Resubmission, or Clone.', 'error');
		}

		//Disposition is Duplicate and Status has been set to Ready for Bill Review
		if (dispositionStatusId.Duplicate === Number(vm.claims.dispositionTypeId) && Number(vm.claims.claimStatusID) === claimStatusId.ReadyForBillReview) {
			return rejectWithToaster('Claim with disposition type \'Duplicate - Claim\' cannot be set to Ready for Bill Review. Please update to a valid disposition for bill review processing. Valid dispositions are standard, reconsideration, resubmission, clone, or duplicate - line.', 'error');
		}

		//Disposition is Fraud and Status has been set to Ready for Bill Review
		if (dispositionStatusId.Fraud === Number(vm.claims.dispositionTypeId) && Number(vm.claims.claimStatusID) === claimStatusId.ReadyForBillReview) {
			return rejectWithToaster('Disposition type \'Fraud\' cannot be sent for bill review', 'error');
		}

		if (Number(vm.claims.claimStatusID) === claimStatusId.ReadyForBillReview && (allowedBillTypeIds.some(item => item === vm.claims.billTypeID) || vm.claims.billTypeID === billTypeId.Rx)) {
			return rejectWithToaster('Bill type RX or any receipt type claims cannot be sent for bill review.', 'error');
		}

		if (Number(vm.claims.claimStatusID) === claimStatusId.ReadyForBillReview && !vm.claims.billedAmount) {
			return rejectWithToaster('Claims with billed Amount as 0 cannot be sent for bill review.', 'error');
		}

		if (vm.claims.payee.exBillReview && Number(vm.claims.claimStatusID) === claimStatusId.ReadyForBillReview) {
			return rejectWithToaster('Provider is excluded from bill review.', 'error');
		}

		if (vm.claims.serviceDate > vm.member.ExpirationDate && Number(vm.claims.claimStatusID) === claimStatusId.ReadyForBillReview) {
			return rejectWithToaster(`Service date is after expiration date. Unable to send claim to bill review.`, 'error');
		}

		if (!vm.claims.receivedDate) {
			return rejectWithToaster('Received date is required.', 'error');
		}

		if (!vm.claims.lineItems?.length) {
			return rejectWithToaster('Please enter at least one line item.', 'warning');
		}

		if (vm.claims.isExpediteRemittance && Number(vm.claims.billEntryTypeID) !== Number(receiptBillTypeID)) {
			return rejectWithToaster('In order to expedite remittance entry type must be receipt.', 'warning');
		}

		if (hasSavingsAmountChanged(vm.claims.savingsAmount) && !isLiabilityPartialPayValid(vm.claims)) {
			return rejectWithToaster('The line item explanation selected is for Zero Payment only.  Please choose a different explanation.', 'warning');
		}

		const lineItemsHaveNonZeroReductions = vm.claims.gridOptionsLineItems.data.some(lineItem =>
			lineItem.billReviewAmount > 0 ||
			lineItem.networkAmount > 0 ||
			lineItem.outOfNetworkAmount > 0 ||
			lineItem.internalReductionAmount > 0
		);

		if (vm.claims.billReview && billReviewIsLiability() && lineItemsHaveNonZeroReductions) {
			return rejectWithToaster(`For ${vm.claims.billReview.billReviewVendorName} claims, only enter savings amount for the claim. Do not enter savings for each line.`, 'error');
		}

		const validationDiagnosisResults = gridUtils.validateDiagnosisGrid(vm.claims.gridOptionsDiagnosis);
		vm.claims.gridOptionsDiagnosis.invalidMessage = validationDiagnosisResults && validationDiagnosisResults.resultInvalidMessage || null;

		const validationLineItemsResults = gridUtils.validateLineItemsGrid(
			vm.claims.gridOptionsLineItems,
			vm.claims,
			getDiagnosisSequenceSelector());
		vm.claims.gridOptionsLineItems.invalidFields = validationLineItemsResults.invalidFields;
		vm.claims.gridOptionsLineItems.customValidationErrors = validationLineItemsResults.customValidationErrors;

		if (validationDiagnosisResults && validationDiagnosisResults.resultInvalidMessage) {
			return rejectWithToaster(validationDiagnosisResults.resultInvalidMessage, 'warning');
		}

		if (!angular.equals({}, vm.claims.gridOptionsLineItems.invalidFields)) {
			return rejectWithToaster('Please fill the required fields before saving!', 'warning');
		}

		if (validationLineItemsResults.customValidationErrors?.length) {
			return rejectWithToaster('Please correct invalid data before saving!', 'warning');
		}

		if (Number(vm.claims.claimStatusID) === claimStatusId.ReadyForBillReview && !vm.claims.billReview) {
			return vm.openBillReviewAssignmentPopup();
		}

		if (!skipTrustAccountConfirmation
			&& Number(vm.claims.claimStatusID) === claimStatusId.ReadyForPayment
			&& trustAllocationTypeIds.includes(vm.member.AllocationTypeId)
			&& receiptBillTypeIds.includes(vm.claims.billTypeID)
		) {
			return vm.openTrustAccountReimbursementConfirmationPopupFunction();
		}

		if (!skipRecalculateFeesPopup &&
			lineItemsService.haveClaimReductionsChanged(vm.claims.gridOptionsLineItems.data, dataReferences.gridOptionsLineItems)) {
			return vm.openFeeRecalculationPopup();
		}

		if (recalculateFeesAction && !accountService.isInRole('ClaimActions')) {
			return rejectWithToaster('Error - access is required to recalculate fees. Cannot save claim.', 'warning');
		}

		//If Reconsideration Checkbox is not marked, clear Reconsideration Note
		if (!vm.claims.isReconsideration)
			vm.claims.reconsiderationNote = null;

		//Required to get modifier codes prior to saving line items
		vm.claims.gridOptionsLineItems.data = lineItemsService.mapToCareHubModelAndFormatDates(vm.claims.lineItems, vm.claims.id);

		vm.claims.lineItems = vm.claims.gridOptionsLineItems?.data;
		vm.claims.diagnosisList = vm.claims.gridOptionsDiagnosis?.data;

		//Update Claim Level Service Dates from line items (already formated dates)
		vm.claims.serviceFromDate = getMinServiceDate(vm.claims.lineItems);
		vm.claims.serviceToDate = getMaxServiceDateEnd(vm.claims.lineItems);

		let promises = [];

		//If lineItem.length < the originalLineItems.Length, we need to call SaveClaimDetail in order to remove extra lines. 
		//New lines will be saved with the claimService.createClaim call.
		if (vm.claims.gridOptionsLineItems?.data?.length < dataReferences.gridOptionsLineItems?.length) {
			promises.push(claimService.saveClaimDetail(vm.claims.gridOptionsLineItems?.data, vm.claims.id));
		}

		vm.isDataLoading = true;

		return $q.all(promises).then(() => {

			mapNamesToCareHubModelNames({ details: vm.claims.lineItems });

			if (!vm.claims.payee.id) {
				return rejectWithToaster('Saving claim without a payee is prohibited.', 'error');
			}

			if (!vm.claims.payee?.address?.address1) {
				return rejectWithToaster('Saving claim without a payee address is prohibited.', 'error');
			}

			return claimService.saveClaim(vm.claims);
		}).then(() => {
			let lineItemsPromise = getNewLineItems(vm.claims.lineItems).length > 0
				? populateLineItemIds(vm.claims.id, vm.claims.lineItems)
				: $q.when(vm.claims.lineItems);

			return lineItemsPromise.then(lineItems => {

				const promises = [];
				promises.push(saveClaimDetailDiagnosisPointers(vm.claims.id, lineItems, vm.claims.diagnosisList));

				const placesOfService = lineItemsService.mapClaimDetailPlacesOfService(lineItems);
				promises.push(claimService.saveClaimDetailPlacesOfService(vm.claims.id, placesOfService));

				if (recalculateFeesAction) promises.push(claimService.recalculateFees(vm.claims.id));

				return $q.all(promises);
			});
		}).then(() => {
			return $q.all([
				getDiagnosisCodeData(vm.claims.id),
				loadClaim()
			]);
		}).catch(err => {
			return rejectWithToaster(utilService.parseErrorMessage(err), `error`);
		}).finally(_ => {
			vm.isDataLoading = false;
		});
	}

	function getMinServiceDate(lineItems) {
		const minDate = _.min(lineItems.map(detail => new Date(detail.serviceDate)));
		return minDate.toJSON();
	}

	function getMaxServiceDateEnd(lineItems) {
		const maxDate = _.max(lineItems.map(detail => new Date(detail.serviceDateEnd)));
		return maxDate.toJSON();
	}

	function mapNamesToCareHubModelNames({ details }) {
		lineItemsService.mapToCareHubCodeModelName(details, vm.claims.id);

		vm.claims.claimID = vm.claims.id;
		vm.claims.payeeID = vm.claims.payee.id;
		vm.claims.payeeAddressID = vm.claims.payee?.address?.payeeAddressID ?? vm.claims.payeeAddressID;
	}

	function generateCustomEOR() {
		vm.isDataLoading = true;
		claimService.generateCustomEOR([vm.claims.id]).then(filenames => {
			vm.claims.files.eorFile = filenames[0];
			$toastr.show(`EOR has been successfully generated.`, `success`);
			return getDocumentData(vm.claims);
		}).finally(() => vm.isDataLoading = false);
	}

	function selectOwner(selected) {
		if (!selected || angular.equals({}, selected)) return;

		vm.claims.ownerId = selected.originalObject.UserName;
	}

	function getImageFile(file) {
		documentService.downloadMemberAzureBlobDocument({ memberNumber: vm.member.MemberNumber, filename: file }).then(response => {
			if (Array.isArray(response?.data) && !response?.data?.length) {
				$toastr.show(`Document not found.`, `warning`);
				return;
			}
			utilService.processResponse({ response });
		});
	}

	function clone(claimid) {
		vm.isDataLoading = true;
		claimService.cloneClaim(claimid, vm.claimCloneTypeId).then(({ data: newClaimId }) => {
			vm.closeCloneDialog();
			vm.navigate('layoutNoNav.claim-details', { 'claimValue': newClaimId });
		}).catch(err => {
			$toastr.show(utilService.parseErrorMessage(err), `error`);
		}).finally(_ => vm.isDataLoading = false);
	}

	function navigate(state, params) {
		$timeout(() => $state.go(state, params), 0);
	}

	function openPopupToChangeAccount() {
		LxDialogService.open(changeMemberDialogID);
	}

	function closeChangeAccountPopup() {
		vm.NewMemberNumber = null;
		LxDialogService.close(changeMemberDialogID);
	}

	function changeAccount() {
		if (!accountService.isInRole('ChangeMemberAccountForClaim')) return;

		return claimService.changeMemberAccountForClaim(vm.member.MemberNumber, vm.NewMemberNumber, vm.claims.id)
			.then(_ => $state.go('layoutNoNav.claim-details',
				{ previousMemberID: vm.memberData, claimValue: vm.claims.id, parentState: vm.parentState })
			).finally(_ => closeChangeAccountPopup());
	}

	function openStopPaymentPopup() {
		LxDialogService.open(stopPaymentDialogID);
	}

	function closeStopPaymentPopup() {
		vm.StopPaymentRequest = {};
		return LxDialogService.close(stopPaymentDialogID);
	}

	function stopPayment() {
		vm.StopPaymentRequest.ClaimID = vm.claims.id;

		claimService.stopPaymentRequests_Create(vm.StopPaymentRequest).then(result => {
			//Stop Payment Request - send email, pass in stop payment request id that was just created
			let sendSPRpromise = sendSPREmail(result); //result = stop payment request id

			return $q.all([
				sendSPRpromise,
				claimService.stopPaymentRequests_Get(null, null, claimValue)
			]);
		}).then(result => {
			vm.stopPaymentData = result.Data;
			vm.stopPaymentButtonDisabled = true;
		}).finally(_ => closeStopPaymentPopup());
	}

	function sendSPREmail(sprID) {
		if (sprID <= 0) return;
		return claimService.stopPaymentRequests_SendRequestEmail(sprID)
	}

	function hasRole(roleName) {
		return accountService.isInRole(roleName);
	}

	vm.onPayMemberChange = function () {
		if (!vm.claims?.payee) return;
		closeTaxIdEdit();

		if (!vm.claims.originalPayee) {
			saveOriginalPayee();
		}

		if (!vm.payMember) {
			resetClaimPayee();
			return;
		}

		if (!vm.member.payee?.id && !vm.member.payee?.address?.id) {
			initializeNewMemberAsPayee().then(() => setMemberAsPayee());
		} else {
			setMemberAsPayee();
		}
	}

	function resetClaimPayee() {
		vm.claims.payee = vm.claims.originalPayee;
	}

	function setMemberAsPayee() {
		vm.claims.payee = vm.member.payee;
	}

	function closeTaxIdEdit() {
		vm.isEditTaxIdOpen = false;
	}

	function saveOriginalPayee() {
		vm.claims.originalPayee = vm.claims.payee;
	}

	function initializeNewMemberAsPayee() {
		return getMemberMailingAddress(vm.claims.memberID).then(address => {
			vm.member.payee = {
				name: `${vm.member.FirstName} ${vm.member.LastName}`,
				taxID: vm.member.fullSSN,
				address,
			};

			vm.newPayeeTaxID = vm.member.SSN;
		});
	}

	function canNoteBeEdited(note) {
		if (vm.gridOptionsNotes?.data.some(x => x.isEdit)) {
			return false;
		}

		return hasRole('EditNote') || accountService.getAccountName() === note.CreatedBy;
	}

	function editNote(note) {
		note.isEdit = true;

		temp.originalNote = angular.copy(note);
	}

	function cancelEditNote(note) {
		note.isEdit = false;

		if (temp.originalNote) {
			note.NoteText = temp.originalNote.NoteText;
			temp.originalNote = null;
		}
	}

	function canNoteBeDeleted(note) {
		if (!note.NoteID) return true;

		if (vm.gridOptionsNotes?.data.some(x => x.isEdit)) return false;

		return hasRole('DeleteNote');
	}

	function canNoteBeAdded() {
		return vm.gridOptionsNotes?.data?.every(x => !x.isEdit);
	}

	function isEditClaimNumberAvailable(billEntryTypeID) {
		return +billEntryTypeID === receiptBillTypeID && !isClaimInPaidStatus();
	}

	function setOpenBillReviewAssignmentPopupFunction(openFunction) {
		createPopupOpenFunctionWithPromise('openBillReviewAssignmentPopup', openFunction);
	}

	function setOpenFeeRecalculationPopupFunction(openFunction) {
		createPopupOpenFunctionWithPromise('openFeeRecalculationPopup', openFunction);
	}

	function setOpenTrustAccountReimbursementConfirmationPopupFunction(openFunction) {
		createPopupOpenFunctionWithPromise('openTrustAccountReimbursementConfirmationPopupFunction', openFunction);
	}

	function assignBillReviewVendor() {
		claimService.assignBillReviewVendor(vm.claims.id).then(workflowInstanceId => {
			vm.claims.billReview = { workflowInstanceId: workflowInstanceId };
			return vm.claims;
		}).then(_ => {
			vm.saveClaimOptions.skipRecalculateFeesPopup = true;
			vm.saveClaimOptions.recalculateFeesAction = false;
			return saveClaimResolvePopupPromise('openBillReviewAssignmentPopup');
		});
	}

	function openCreateBillReviewReprocessPopup() {
		LxDialogService.open(billReviewReprocessPopupId);
	}

	function closeBillReviewReprocessPopup() {
		vm.newBillReviewReprocess = null;
		LxDialogService.close(billReviewReprocessPopupId);
	}

	function createBillReviewReprocess() {
		if (!accountService.isInRole('ClaimActions')) {
			$toastr.show('Bill review reprocess is editable for claims role only.', 'warning');
			return;
		}

		if (!vm.newBillReviewReprocess) {
			$toastr.show('Bill review reprocess can\'t be empty.', 'warning');
			return;
		}

		if (!vm.newBillReviewReprocess.reasonId) {
			$toastr.show('Bill review reprocess reason is not selected.', 'warning');
			return;
		}

		if (vm.newBillReviewReprocess.reasonId == vm.customBillReviewReprocessReasonId) {
			if (!vm.newBillReviewReprocess.customReason) {
				$toastr.show('Custom reason can\'t be empty.', 'warning');
				return;
			}
			if (vm.newBillReviewReprocess.customReason.length > 50) {
				$toastr.show('Custom reason can\'t be longer than 50 characters.', 'warning');
				return;
			}
		}

		adjustBillReviewReprocessReason(vm.newBillReviewReprocess);

		const existingEors = getCurrentCustomAndVendorEors();

		return billReviewSagaService.createBillReviewReprocess(
			vm.claims.id,
			vm.claims.billReview.billReviewWorkflowId,
			vm.newBillReviewReprocess.reason,
			existingEors)
			.then(() => {
				closeBillReviewReprocessPopup();
				return loadClaim().then(() => getDocumentData(vm.claims));
			})
			.catch(({ data }) => $toastr.show(data.title, `error`));
	}

	function adjustBillReviewReprocessReason(reprocess) {
		if (!reprocess) return;

		const reason = reprocess.reasonId == vm.customBillReviewReprocessReasonId
			? formatCustomBillReviewReprocessReason(reprocess.customReason)
			: vm.billReviewReprocessReasons.find(x => x.id == reprocess.reasonId).value;

		reprocess.reason = reason;
	}

	function formatCustomBillReviewReprocessReason(reason) {
		return customBillReviewReprocessReasonPrefix + reason;
	}

	function getExistingEorData() {
		var file = vm.claims?.files;

		if (!file || !file.currentEor) {
			return null;
		}

		file.currentEor.memberNumber = vm.member.MemberNumber;
		return file.currentEor;
	}

	function getCurrentCustomAndVendorEors() {
		var file = vm.claims?.files;

		if (!file || !file.allEors) {
			return [];
		}

		var eors = file.allEors.filter(e => e.isCurrent ||
			(vm.claims.billReview && (e.billReviewResultId == vm.claims.billReview.billReviewResultId)));
		eors.forEach(e => {
			e.memberNumber = vm.member.MemberNumber;
		});
		return eors;
	}

	function isBillReviewReprocessButtonDisabled() {
		let triggered = billReviewReprocessDisableConditions.find(x => x.condition());

		return !!triggered;
	}

	function getBillReviewReprocessButtonTooltip() {
		let triggered = billReviewReprocessDisableConditions.find(x => x.condition());

		if (triggered) {
			return triggered.tooltip;
		}

		return '';
	}

	function hasCurrentBillReviewReprocessChanged() {
		const original = dataReferences.currentBillReviewReprocess;
		const current = vm.currentBillReviewReprocess;

		if (!original || !current) return false;

		if (current.reasonId != original.reasonId) return true;

		if (current.reasonId == vm.customBillReviewReprocessReasonId &&
			current.customReason != original.customReason) return true;

		return false;
	}

	function updateCurrentBillReviewReprocess() {
		adjustBillReviewReprocessReason(vm.currentBillReviewReprocess);

		return billReviewService.updateBillReviewReprocess(vm.currentBillReviewReprocess)
			.then(() => loadClaim())
			.catch(({ data }) => $toastr.show(data.title, `error`));;
	}

	function resetCurrentBillReviewReprocess() {
		angular.copy(dataReferences.currentBillReviewReprocess, vm.currentBillReviewReprocess);
	}

	function canCurrentBillReviewReprocessBeEdited() {
		return vm.claims.claimStatusID != claimStatusId.AwaitingBillReview;
	}

	function isShowBillReviewReprocessIndicator() {
		return vm.gridOptionsBillReviewReprocess?.data?.length > 0;
	}

	function canBeZeroPaid() {
		return claimService.canBeZeroPaid(vm.claims);
	}

	function openChangeBillReviewVendorPopup() {
		billReviewWorkflowService.getAllWorkflowDefinitions().then(
			workflows => {
				vm.billReviewWorkflows = workflows;
				LxDialogService.open(changeBillReviewVendorPopupId);
			}
		);
	}

	function closeChangeBillReviewVendorPopup() {
		LxDialogService.close(changeBillReviewVendorPopupId);
		vm.billReviewWorkflows = undefined;
	}

	function changeBillReviewWorkflow() {
		var newBillReviewWorkflowId = +vm.newBillReviewWorkflowId;
		delete vm.newBillReviewWorkflowId;

		return billReviewSagaService.changeBillReviewWorkflow(
			vm.claims.id,
			newBillReviewWorkflowId,
			getExistingEorData()
		).then(() => {
			vm.claims.claimStatusID = claimStatusId.ReadyForBillReview;
			vm.claims.ppoNetworkName = "";
			return vm.saveClaims(vm.saveClaimOptions);
		}).then(() => {
			closeChangeBillReviewVendorPopup();
			return getDocumentData(vm.claims);
		}).catch(({ data }) => {
			$toastr.show(data.title, `error`);
		});
	}

	function isChangeBrVendorDisabled() {
		return isClaimInPaidStatus() || vm.claims.paidDate || (vm.claims.billReview && vm.claims.billReview.billReviewReceivedDate);
	}

	function getDocumentTypes() {
		lookupService.getLookUp("documentType").then(function (result) {
			vm.lookupdata.documentTypes = result.Data;
		});
	}

	function saveClaimDetailDiagnosisPointers(claimId, lineItems, diagnoses) {
		var pointers = lineItemsService.mapBackDiagnosisCodePointersFromPlain(
			claimId,
			lineItems,
			diagnoses,
			getDiagnosisSequenceSelector()
		);

		return claimService.saveClaimDetailDiagnosisPointers(claimId, pointers);
	}

	function getDiagnosisSequenceSelector() {
		return diagnosis => diagnosis.sequence;
	}

	function redirectToNonRedeemedClaimsPage() {
		vm.navigate('layoutNoNav.nonRedeemedClaims', { 'ClaimId': vm.claims.id });
	}

	function isClaimNonRedeemed() {
		if (!vm.claims || !vm.claims.claimStatusID) return false;

		return claimService.isClaimNonRedeemed(vm.claims.claimStatusID);
	}

	function assignClaimToPreBillReviewFlowDisabled() {
		var enabled = claimService.isClaim837BillType(+vm.claims.billTypeID)
			&& (!vm.claims.billReview)
			&& vm.claims.claimStatusID == claimStatusId.ManualEntry;

		return !enabled;
	}

	function assignClaimToPreBillReviewFlow() {
		var alreadyHandledSaveErr = false;
		return vm.saveClaims(vm.saveClaimOptions)
			.catch((handledSaveErr) => {
				alreadyHandledSaveErr = true;
				return $q.reject(handledSaveErr);
			})
			.then(() => {
				return claimService.assignClaimToPreBillReviewFlow(vm.claims.id);
			})
			.then(() => getClaimData(vm.claims.id))
			.catch((err) => {
				if (!alreadyHandledSaveErr) {
					$toastr.show(err.data?.title || utilService.parseErrorMessage(err), err.severity || `error`);
				}
			});
	}

	function populateLineItemIds(claimId, existingLineItems) {
		return claimService.getClaimDetailsByClaimId(claimId)
			.then(({ data: updatedLineItems }) => assignCreatedLineItemIds(existingLineItems, updatedLineItems));
	}

	function assignCreatedLineItemIds(existingLineItems, updatedLineItems) {
		for (let existing of getNewLineItems(existingLineItems)) {
			const updated = updatedLineItems.find(x => x.lineNumber === existing.lineNumber);

			if (!existing) throw new Error(`Line item with line number = ${existing.lineNumber} is not found`);

			existing.id = updated.id;
		}

		return existingLineItems;
	}

	function getNewLineItems(lineItems) {
		return lineItems.filter(lineItem => !lineItem.id);
	}

	function filterOutPassedUncheckedRuleResults(ruleResults) {
		return ruleResults.filter(x => !x.IsPassed && x.ClaimId);
	}

	function toggleShowAllRuleResults() {
		renderRuleResults();
	}

	function toggleShowAllBillReviewRuleResults() {
		renderBillReviewRuleResults();
	}

	function getRuleResultCheckboxLabel() {
		return getGenericRuleResultCheckboxLabel(vm.showAllRuleResults, originalRuleResults);
	}

	function getBillReviewRuleResultCheckboxLabel() {
		return getGenericRuleResultCheckboxLabel(vm.showAllBillReviewRuleResults, originalBillReviewRuleResults);
	}

	function getGenericRuleResultCheckboxLabel(showAllFlag, originalData) {
		let passedUncheckedRulesAmount = 0;
		if (originalData.length) {
			const failedRuleResults = filterOutPassedUncheckedRuleResults(originalData);
			passedUncheckedRulesAmount = originalData.length - failedRuleResults.length;
		}

		return (showAllFlag ? 'Hide' : 'Show') + ' passed/unchecked rules ('
			+ (showAllFlag ? '-' : '+') + passedUncheckedRulesAmount + ')';
	}

	/*** saveClaim() popup promises ***/

	function getPopupPromiseName(popupPropertyName) {
		return `${popupPropertyName}Promise`;
	}

	function createPopupOpenFunctionWithPromise(popupPropertyName, openFunction) {
		vm[popupPropertyName] = function () {
			var popupPromiseProperty = getPopupPromiseName(popupPropertyName);
			var deferred = $q.defer();
			vm[popupPromiseProperty] = deferred;
			openFunction.apply(vm, arguments);
			return deferred.promise;
		}
	}

	function saveClaimResolvePopupPromise(popupPropertyName) {
		var popupPromiseProperty = getPopupPromiseName(popupPropertyName);
		return saveClaims(vm.saveClaimOptions).then(function () {
			var deferred = vm[popupPromiseProperty];
			vm[popupPromiseProperty] = null;
			deferred.resolve();
			return deferred.promise;
		})
			.catch(function (err) {
				var deferred = vm[popupPromiseProperty];
				vm[popupPromiseProperty] = null;
				deferred.reject(err);
				return deferred.promise;
			});
	}

	/*** end of "saveClaim() popup promises" ***/

	function billReviewIsMedicare() {
		return vm.claims.billReview &&
			vm.claims.billReview.workflowReductionType === billReviewReductionTypes.Medicare;
	}

	function billReviewIsLiability() {
		return vm.claims.billReview &&
			vm.claims.billReview.workflowReductionType === billReviewReductionTypes.Liability;
	}
}
