
angular
	.module('CareGuard')
	.factory('billReviewWorkflowService', billReviewWorkflowService);

billReviewWorkflowService.$inject = ['$q', 'billReviewService', 'billReviewReductionTypes', 'billReviewWorkflows'];

function billReviewWorkflowService($q, billReviewService, billReviewReductionTypes, billReviewWorkflows) {

	let cachedWorkflows;
	let cachedVendors;

	function getAllWorkflowDefinitions() {
		if (cachedWorkflows) {
			return $q.when(cachedWorkflows);
		}

		return billReviewService.getAllWorkflowDefinitions().then(r => {
			cachedWorkflows = r.data.filter(d => d.id > 0);
			const workflowToUpdate = cachedWorkflows.find(d => d.id == billReviewWorkflows.RenovoMedicare);

			const newVendor = {
				billReviewVendorId: 6,
				reductionType: 1,
				isDefault: false
			};

			workflowToUpdate.vendors.push(newVendor);

			return cachedWorkflows;
		});
	}

	function getAllVendors() {
		if (cachedVendors) {
			return $q.when(cachedVendors);
		}

		return billReviewService.getVendors().then(({ data: allVendors }) => cachedVendors = allVendors);
	}

	function getWorkflow(billReviewWorkflowId) {
		return getAllWorkflowDefinitions().then(workflowList => {
			return workflowList.find(w => w.id == billReviewWorkflowId);
		});
	}

	function createWorkflowInstance(
		resultInstance,
		billReviewInstance,
		workflowData,
		allVendorList
	) {
		resultInstance.workflowInstanceId = billReviewInstance.workflowInstanceId;
		resultInstance.billReviewSentDate = billReviewInstance.startDate;
		resultInstance.billReviewReceivedDate = billReviewInstance.endDate;
		resultInstance.billReviewWorkflowId = workflowData.id;
		resultInstance.workflowDisplayName = workflowData.workflowDisplayName;

		var currentResult = billReviewInstance.currentResult;

		var defaultVendor = workflowData.vendors.find(v => v.isDefault);
		resultInstance.workflowReductionType = defaultVendor.reductionType


		if (currentResult) {
			resultInstance.billReviewResultId = currentResult.billReviewResultId;
			resultInstance.billReviewVendorId = currentResult.billReviewVendorId;
			resultInstance.billReviewRateId = currentResult.billReviewRateId;
			resultInstance.ppoNetworkId = currentResult.ppoNetworkId;
			resultInstance.currentReductionType = currentResult.reductionType;
		}
		else {
			resultInstance.billReviewResultId = null;
			resultInstance.billReviewVendorId = defaultVendor.billReviewVendorId;
			resultInstance.currentReductionType = resultInstance.workflowReductionType;
		}

		var currentVendor = allVendorList.find(v => v.id == resultInstance.billReviewVendorId);
		resultInstance.hasLineItemReductions = currentVendor.hasLineItemReductions;
		resultInstance.billReviewVendorName = currentVendor.name;

		if (resultInstance.workflowReductionType == billReviewReductionTypes.Medicare) {
			var wcfsVendor = workflowData.vendors.find(v => v.reductionType == billReviewReductionTypes.Wcfs);
			resultInstance.feeScheduleVendorName = allVendorList.find(v => v.id == wcfsVendor.billReviewVendorId).name;
		}
		return resultInstance;
	}

	function getBillReviewWorkflowInstance(claimId) {
		var resultInstance = {
			workflowInstanceId: null,
			workflowDisplayName: '',
			billReviewVendorName: '',
			feeScheduleVendorName: null,
			billReviewRateId: null,
			ppoNetworkId: null,
			workflowReductionType: null,
			currentReductionType: null
		};

		return billReviewService.getBillReviewWorkflowInstance(claimId)
			.then(r => {
				if (r.data && r.data.length == 1) {
					return r.data[0];
				}
				return $q.reject(resultInstance);
			}).then(billReviewInstance => {
				return getAllVendors()
					.then(allVendorList =>
						getWorkflow(billReviewInstance.billReviewWorkflow)
							.then(workflowData =>
								createWorkflowInstance(
									resultInstance,
									billReviewInstance,
									workflowData,
									allVendorList)
							)
					)
			});
	}

	return {
		getBillReviewWorkflowInstance,
		getAllWorkflowDefinitions
	};
};