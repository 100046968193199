
angular
    .module('CareGuard')
    .factory('billReviewService', billReviewService);

billReviewService.$inject = ['$http', '$q', 'billReviewVendors'];

function billReviewService($http, $q, billReviewVendors) {
    // External API calls
    const baseRoute = `brvs`;

    function getById(id) {
        return $http.get(`${apiUrl}/${baseRoute}/${id}`);
    }

    function getBillReviewReprocessesByClaimId(claimId) {
        return $http.get(`${apiUrl}/${baseRoute}/claims/${claimId}/reprocesses`);
    }

    function getAllWorkflowDefinitions() {
        return $http.get(`${apiUrl}/${baseRoute}/workflows`);
    }

    function getVendors() {
        return $http.get(`${apiUrl}/${baseRoute}/vendors`);
    }

    function createBillReviewReprocess(claimId, reason) {
        return $http.post(`${apiUrl}/${baseRoute}/reprocess`,
            {
                claimId,
                reason
            });
    }

    function updateBillReviewReprocess(billReviewReprocess) {
        return $http.patch(`${apiUrl}/${baseRoute}/reprocess`, billReviewReprocess);
    }

    function changeBillReviewReprocessStatus(reprocessId, statusId) {
        return $http.patch(`${apiUrl}/${baseRoute}/reprocess/${reprocessId}/status`, statusId);
    }

    // CH C# controller calls
    const baseUrl = '/api/billreview/';
   
    function filterBills(acknowledged, recordtype, insurancetype, claimid, startdate, enddate, pageindex, pagesize, sortcolumn, sortorder) {
        return $http.get(baseUrl + 'filterBills/' + acknowledged + '/' + recordtype + '/' + insurancetype + '/' + claimid + '/' + startdate + '/' + enddate + '/' + pageindex + '/' + pagesize + '/' + sortcolumn + '/' + sortorder);
    }

    function getImageFile(membernumber, documentname, extension) {
        return $http.get(baseUrl + 'getImageFile/', membernumber + '/' + documentname + '/' + extension);
    }
      
    function acknowledgeBill(billreviewid) {
        return $http.get(baseUrl + 'acknowledgeBill/' + billreviewid);
    }

    function applyResolutions(billreviewchanges) {
        return $http.post(baseUrl + 'applyResolutions', billreviewchanges);
    }

    function getAuditInfo() {
        return $http.get(baseUrl + 'getAuditInfo');
    }

    function exportBills(acknowledged, recordtype, insurancetype, claimid, startdate, enddate) {
        return $http.get(baseUrl + 'exportBills/' + acknowledged + '/' + recordtype + '/' + insurancetype + '/' + claimid + '/' + startdate + '/' + enddate);
    }

    const resetClaimForBillReview = (claimId, billReviewWorkflowId) => {
        return $http.patch(`${apiUrl}/${baseRoute}/${billReviewWorkflowId}/reset/${claimId}`);
    };

    const changeClaimBillReviewWorkflow = (claimId, billReviewWorkflowId) => {
        return $http.patch(`${apiUrl}/${baseRoute}/assignments`, {
            ClaimId: claimId,
            BillReviewWorkflow: billReviewWorkflowId
        });
    };

    const getBillReviewWorkflowInstance = function (claimId) {
        return $http.post(`${apiUrl}/${baseRoute}/instances`, [claimId]);
    };

    const setCurrentBillReviewResult = (billReviewWorkflowInstanceId, brResultId) => {
        return $http.patch(`${apiUrl}/${baseRoute}/${billReviewWorkflowInstanceId}/current/${brResultId}`);
    };

    const cancelBillReview = (billReviewWorkflowInstanceId) => {
        return $http.patch(`${apiUrl}/${baseRoute}/${billReviewWorkflowInstanceId}/cancel`);
    };

    const setCurrentEor = function (claimId, documentId, isCurrent) {
        return $http.post(`${baseUrl}setCurrentEor/${claimId}/${documentId}/${isCurrent}`);
    };

    const addDocumentEor = function (claimId, documentId) {
        return $http.post(`${baseUrl}addDocumentEor/${claimId}/${documentId}`);
    };

    return {
        // External API calls
        getById,
        getBillReviewReprocessesByClaimId,
        getAllWorkflowDefinitions,
        getVendors,
        createBillReviewReprocess,
        updateBillReviewReprocess,
        changeBillReviewReprocessStatus,
        // CH C# controller calls
        filterBills,
        getImageFile,
        acknowledgeBill,
        applyResolutions,
        getAuditInfo,
        exportBills,
        resetClaimForBillReview,
        changeClaimBillReviewWorkflow,
        getBillReviewWorkflowInstance,
        setCurrentBillReviewResult,
        cancelBillReview,
        setCurrentEor,
        addDocumentEor
    };
};